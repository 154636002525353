/* eslint-disable */
import * as Types from '../types.generated';

import {
    NonRecursiveDatasetFieldsFragment,
    OwnershipFieldsFragment,
    GlobalTagsFieldsFragment,
    DataJobFieldsFragment,
    GlossaryTermsFragment,
} from './fragments.generated';
import { DownstreamRelationshipFieldsFragment, UpstreamRelationshipFieldsFragment } from './genericLineage.generated';
import { gql } from '@apollo/client';
import {
    NonRecursiveDatasetFieldsFragmentDoc,
    OwnershipFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    DataJobFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
} from './fragments.generated';
import {
    DownstreamRelationshipFieldsFragmentDoc,
    UpstreamRelationshipFieldsFragmentDoc,
} from './genericLineage.generated';
import * as Apollo from '@apollo/client';
export type UpdateDatasetMutationVariables = Types.Exact<{
    input: Types.DatasetUpdateInput;
}>;

export type UpdateDatasetMutation = { __typename?: 'Mutation' } & {
    updateDataset?: Types.Maybe<
        { __typename?: 'Dataset' } & {
            downstreamLineage?: Types.Maybe<
                { __typename?: 'DownstreamEntityRelationships' } & DownstreamRelationshipFieldsFragment
            >;
            upstreamLineage?: Types.Maybe<
                { __typename?: 'UpstreamEntityRelationships' } & UpstreamRelationshipFieldsFragment
            >;
        } & NonRecursiveDatasetFieldsFragment
    >;
};

export type GetDatasetQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetDatasetQuery = { __typename?: 'Query' } & {
    dataset?: Types.Maybe<
        { __typename?: 'Dataset' } & Pick<
            Types.Dataset,
            'urn' | 'name' | 'type' | 'origin' | 'description' | 'uri' | 'platformNativeType' | 'tags'
        > & {
                platform: { __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'name'> & {
                        info?: Types.Maybe<
                            { __typename?: 'DataPlatformInfo' } & Pick<Types.DataPlatformInfo, 'logoUrl'>
                        >;
                    };
                properties?: Types.Maybe<
                    Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                >;
                ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                institutionalMemory?: Types.Maybe<
                    { __typename?: 'InstitutionalMemory' } & {
                        elements: Array<
                            { __typename?: 'InstitutionalMemoryMetadata' } & Pick<
                                Types.InstitutionalMemoryMetadata,
                                'url' | 'description'
                            > & {
                                    author: { __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'username'>;
                                    created: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'actor' | 'time'>;
                                }
                        >;
                    }
                >;
                schema?: Types.Maybe<
                    { __typename?: 'Schema' } & Pick<
                        Types.Schema,
                        'datasetUrn' | 'name' | 'platformUrn' | 'version' | 'hash' | 'primaryKeys'
                    > & {
                            platformSchema?: Types.Maybe<
                                | ({ __typename?: 'TableSchema' } & Pick<Types.TableSchema, 'schema'>)
                                | ({ __typename?: 'KeyValueSchema' } & Pick<
                                      Types.KeyValueSchema,
                                      'keySchema' | 'valueSchema'
                                  >)
                            >;
                            fields: Array<
                                { __typename?: 'SchemaField' } & Pick<
                                    Types.SchemaField,
                                    | 'fieldPath'
                                    | 'jsonPath'
                                    | 'nullable'
                                    | 'description'
                                    | 'type'
                                    | 'nativeDataType'
                                    | 'recursive'
                                > & {
                                        globalTags?: Types.Maybe<
                                            { __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment
                                        >;
                                        glossaryTerms?: Types.Maybe<
                                            { __typename?: 'GlossaryTerms' } & GlossaryTermsFragment
                                        >;
                                    }
                            >;
                        }
                >;
                editableSchemaMetadata?: Types.Maybe<
                    { __typename?: 'EditableSchemaMetadata' } & {
                        editableSchemaFieldInfo: Array<
                            { __typename?: 'EditableSchemaFieldInfo' } & Pick<
                                Types.EditableSchemaFieldInfo,
                                'fieldPath' | 'description'
                            > & { globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment> }
                        >;
                    }
                >;
                deprecation?: Types.Maybe<
                    { __typename?: 'DatasetDeprecation' } & Pick<
                        Types.DatasetDeprecation,
                        'actor' | 'deprecated' | 'note' | 'decommissionTime'
                    >
                >;
                globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                downstreamLineage?: Types.Maybe<
                    { __typename?: 'DownstreamEntityRelationships' } & DownstreamRelationshipFieldsFragment
                >;
                glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
                upstreamLineage?: Types.Maybe<
                    { __typename?: 'UpstreamEntityRelationships' } & UpstreamRelationshipFieldsFragment
                >;
            }
    >;
};

export const UpdateDatasetDocument = gql`
    mutation updateDataset($input: DatasetUpdateInput!) {
        updateDataset(input: $input) {
            ...nonRecursiveDatasetFields
            downstreamLineage {
                ...downstreamRelationshipFields
            }
            upstreamLineage {
                ...upstreamRelationshipFields
            }
        }
    }
    ${NonRecursiveDatasetFieldsFragmentDoc}
    ${DownstreamRelationshipFieldsFragmentDoc}
    ${UpstreamRelationshipFieldsFragmentDoc}
`;
export type UpdateDatasetMutationFn = Apollo.MutationFunction<UpdateDatasetMutation, UpdateDatasetMutationVariables>;

/**
 * __useUpdateDatasetMutation__
 *
 * To run a mutation, you first call `useUpdateDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDatasetMutation, { data, loading, error }] = useUpdateDatasetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDatasetMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateDatasetMutation, UpdateDatasetMutationVariables>,
) {
    return Apollo.useMutation<UpdateDatasetMutation, UpdateDatasetMutationVariables>(
        UpdateDatasetDocument,
        baseOptions,
    );
}
export type UpdateDatasetMutationHookResult = ReturnType<typeof useUpdateDatasetMutation>;
export type UpdateDatasetMutationResult = Apollo.MutationResult<UpdateDatasetMutation>;
export type UpdateDatasetMutationOptions = Apollo.BaseMutationOptions<
    UpdateDatasetMutation,
    UpdateDatasetMutationVariables
>;
export const GetDatasetDocument = gql`
    query getDataset($urn: String!) {
        dataset(urn: $urn) {
            urn
            name
            type
            origin
            description
            uri
            platform {
                name
                info {
                    logoUrl
                }
            }
            platformNativeType
            tags
            properties {
                key
                value
            }
            ownership {
                ...ownershipFields
            }
            institutionalMemory {
                elements {
                    url
                    author {
                        urn
                        username
                    }
                    description
                    created {
                        actor
                        time
                    }
                }
            }
            schema {
                datasetUrn
                name
                platformUrn
                version
                hash
                platformSchema {
                    ... on TableSchema {
                        schema
                    }
                    ... on KeyValueSchema {
                        keySchema
                        valueSchema
                    }
                }
                fields {
                    fieldPath
                    jsonPath
                    nullable
                    description
                    type
                    nativeDataType
                    recursive
                    globalTags {
                        ...globalTagsFields
                    }
                    glossaryTerms {
                        ...glossaryTerms
                    }
                }
                primaryKeys
            }
            editableSchemaMetadata {
                editableSchemaFieldInfo {
                    fieldPath
                    description
                    globalTags {
                        ...globalTagsFields
                    }
                }
            }
            deprecation {
                actor
                deprecated
                note
                decommissionTime
            }
            globalTags {
                ...globalTagsFields
            }
            downstreamLineage {
                ...downstreamRelationshipFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            upstreamLineage {
                ...upstreamRelationshipFields
            }
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${DownstreamRelationshipFieldsFragmentDoc}
    ${UpstreamRelationshipFieldsFragmentDoc}
`;

/**
 * __useGetDatasetQuery__
 *
 * To run a query within a React component, call `useGetDatasetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetDatasetQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetQuery, GetDatasetQueryVariables>) {
    return Apollo.useQuery<GetDatasetQuery, GetDatasetQueryVariables>(GetDatasetDocument, baseOptions);
}
export function useGetDatasetLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetQuery, GetDatasetQueryVariables>,
) {
    return Apollo.useLazyQuery<GetDatasetQuery, GetDatasetQueryVariables>(GetDatasetDocument, baseOptions);
}
export type GetDatasetQueryHookResult = ReturnType<typeof useGetDatasetQuery>;
export type GetDatasetLazyQueryHookResult = ReturnType<typeof useGetDatasetLazyQuery>;
export type GetDatasetQueryResult = Apollo.QueryResult<GetDatasetQuery, GetDatasetQueryVariables>;
