/* eslint-disable */
import * as Types from '../types.generated';

import {
    OwnershipFieldsFragment,
    GlobalTagsFieldsFragment,
    DataJobFieldsFragment,
    GlossaryTermsFragment,
    NonRecursiveDataFlowFieldsFragment,
    NonRecursiveDatasetFieldsFragment,
} from './fragments.generated';
import { DownstreamRelationshipFieldsFragment, UpstreamRelationshipFieldsFragment } from './genericLineage.generated';
import { gql } from '@apollo/client';
import {
    OwnershipFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    DataJobFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
    NonRecursiveDatasetFieldsFragmentDoc,
} from './fragments.generated';
import {
    DownstreamRelationshipFieldsFragmentDoc,
    UpstreamRelationshipFieldsFragmentDoc,
} from './genericLineage.generated';
import * as Apollo from '@apollo/client';
export type GetChartQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetChartQuery = { __typename?: 'Query' } & {
    chart?: Types.Maybe<
        { __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type' | 'tool' | 'chartId'> & {
                info?: Types.Maybe<
                    { __typename?: 'ChartInfo' } & Pick<
                        Types.ChartInfo,
                        'name' | 'description' | 'externalUrl' | 'type' | 'access' | 'lastRefreshed'
                    > & {
                            inputs?: Types.Maybe<
                                Array<
                                    { __typename?: 'Dataset' } & Pick<
                                        Types.Dataset,
                                        'urn' | 'name' | 'origin' | 'description' | 'platformNativeType' | 'tags'
                                    > & {
                                            platform: { __typename?: 'DataPlatform' } & Pick<
                                                Types.DataPlatform,
                                                'name'
                                            > & {
                                                    info?: Types.Maybe<
                                                        { __typename?: 'DataPlatformInfo' } & Pick<
                                                            Types.DataPlatformInfo,
                                                            'logoUrl'
                                                        >
                                                    >;
                                                };
                                            ownership?: Types.Maybe<
                                                { __typename?: 'Ownership' } & OwnershipFieldsFragment
                                            >;
                                            downstreamLineage?: Types.Maybe<
                                                {
                                                    __typename?: 'DownstreamEntityRelationships';
                                                } & DownstreamRelationshipFieldsFragment
                                            >;
                                            upstreamLineage?: Types.Maybe<
                                                {
                                                    __typename?: 'UpstreamEntityRelationships';
                                                } & UpstreamRelationshipFieldsFragment
                                            >;
                                        }
                                >
                            >;
                            lastModified: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'>;
                            created: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'>;
                            customProperties?: Types.Maybe<
                                Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                            >;
                        }
                >;
                query?: Types.Maybe<{ __typename?: 'ChartQuery' } & Pick<Types.ChartQuery, 'rawQuery' | 'type'>>;
                ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                downstreamLineage?: Types.Maybe<
                    { __typename?: 'DownstreamEntityRelationships' } & DownstreamRelationshipFieldsFragment
                >;
                upstreamLineage?: Types.Maybe<
                    { __typename?: 'UpstreamEntityRelationships' } & UpstreamRelationshipFieldsFragment
                >;
            }
    >;
};

export type UpdateChartMutationVariables = Types.Exact<{
    input: Types.ChartUpdateInput;
}>;

export type UpdateChartMutation = { __typename?: 'Mutation' } & {
    updateChart?: Types.Maybe<
        { __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type' | 'tool' | 'chartId'> & {
                info?: Types.Maybe<
                    { __typename?: 'ChartInfo' } & Pick<
                        Types.ChartInfo,
                        'name' | 'description' | 'externalUrl' | 'type' | 'access' | 'lastRefreshed'
                    > & {
                            inputs?: Types.Maybe<
                                Array<
                                    { __typename?: 'Dataset' } & Pick<
                                        Types.Dataset,
                                        'urn' | 'name' | 'origin' | 'description' | 'platformNativeType' | 'tags'
                                    > & {
                                            platform: { __typename?: 'DataPlatform' } & Pick<
                                                Types.DataPlatform,
                                                'name'
                                            > & {
                                                    info?: Types.Maybe<
                                                        { __typename?: 'DataPlatformInfo' } & Pick<
                                                            Types.DataPlatformInfo,
                                                            'logoUrl'
                                                        >
                                                    >;
                                                };
                                            ownership?: Types.Maybe<
                                                { __typename?: 'Ownership' } & OwnershipFieldsFragment
                                            >;
                                            downstreamLineage?: Types.Maybe<
                                                {
                                                    __typename?: 'DownstreamEntityRelationships';
                                                } & DownstreamRelationshipFieldsFragment
                                            >;
                                            upstreamLineage?: Types.Maybe<
                                                {
                                                    __typename?: 'UpstreamEntityRelationships';
                                                } & UpstreamRelationshipFieldsFragment
                                            >;
                                        }
                                >
                            >;
                            lastModified: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'>;
                            created: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'>;
                            customProperties?: Types.Maybe<
                                Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                            >;
                        }
                >;
                query?: Types.Maybe<{ __typename?: 'ChartQuery' } & Pick<Types.ChartQuery, 'rawQuery' | 'type'>>;
                ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                downstreamLineage?: Types.Maybe<
                    { __typename?: 'DownstreamEntityRelationships' } & DownstreamRelationshipFieldsFragment
                >;
                upstreamLineage?: Types.Maybe<
                    { __typename?: 'UpstreamEntityRelationships' } & UpstreamRelationshipFieldsFragment
                >;
            }
    >;
};

export const GetChartDocument = gql`
    query getChart($urn: String!) {
        chart(urn: $urn) {
            urn
            type
            tool
            chartId
            info {
                name
                description
                inputs {
                    urn
                    name
                    origin
                    description
                    platform {
                        name
                        info {
                            logoUrl
                        }
                    }
                    platformNativeType
                    tags
                    ownership {
                        ...ownershipFields
                    }
                    downstreamLineage {
                        ...downstreamRelationshipFields
                    }
                    upstreamLineage {
                        ...upstreamRelationshipFields
                    }
                }
                externalUrl
                type
                access
                lastRefreshed
                lastModified {
                    time
                }
                created {
                    time
                }
                customProperties {
                    key
                    value
                }
            }
            query {
                rawQuery
                type
            }
            ownership {
                ...ownershipFields
            }
            globalTags {
                ...globalTagsFields
            }
            downstreamLineage {
                ...downstreamRelationshipFields
            }
            upstreamLineage {
                ...upstreamRelationshipFields
            }
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${DownstreamRelationshipFieldsFragmentDoc}
    ${UpstreamRelationshipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
`;

/**
 * __useGetChartQuery__
 *
 * To run a query within a React component, call `useGetChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetChartQuery(baseOptions: Apollo.QueryHookOptions<GetChartQuery, GetChartQueryVariables>) {
    return Apollo.useQuery<GetChartQuery, GetChartQueryVariables>(GetChartDocument, baseOptions);
}
export function useGetChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChartQuery, GetChartQueryVariables>) {
    return Apollo.useLazyQuery<GetChartQuery, GetChartQueryVariables>(GetChartDocument, baseOptions);
}
export type GetChartQueryHookResult = ReturnType<typeof useGetChartQuery>;
export type GetChartLazyQueryHookResult = ReturnType<typeof useGetChartLazyQuery>;
export type GetChartQueryResult = Apollo.QueryResult<GetChartQuery, GetChartQueryVariables>;
export const UpdateChartDocument = gql`
    mutation updateChart($input: ChartUpdateInput!) {
        updateChart(input: $input) {
            urn
            type
            tool
            chartId
            info {
                name
                description
                inputs {
                    urn
                    name
                    origin
                    description
                    platform {
                        name
                        info {
                            logoUrl
                        }
                    }
                    platformNativeType
                    tags
                    ownership {
                        ...ownershipFields
                    }
                    downstreamLineage {
                        ...downstreamRelationshipFields
                    }
                    upstreamLineage {
                        ...upstreamRelationshipFields
                    }
                }
                externalUrl
                type
                access
                lastRefreshed
                lastModified {
                    time
                }
                created {
                    time
                }
                customProperties {
                    key
                    value
                }
            }
            query {
                rawQuery
                type
            }
            ownership {
                ...ownershipFields
            }
            globalTags {
                ...globalTagsFields
            }
            downstreamLineage {
                ...downstreamRelationshipFields
            }
            upstreamLineage {
                ...upstreamRelationshipFields
            }
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${DownstreamRelationshipFieldsFragmentDoc}
    ${UpstreamRelationshipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
`;
export type UpdateChartMutationFn = Apollo.MutationFunction<UpdateChartMutation, UpdateChartMutationVariables>;

/**
 * __useUpdateChartMutation__
 *
 * To run a mutation, you first call `useUpdateChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChartMutation, { data, loading, error }] = useUpdateChartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChartMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateChartMutation, UpdateChartMutationVariables>,
) {
    return Apollo.useMutation<UpdateChartMutation, UpdateChartMutationVariables>(UpdateChartDocument, baseOptions);
}
export type UpdateChartMutationHookResult = ReturnType<typeof useUpdateChartMutation>;
export type UpdateChartMutationResult = Apollo.MutationResult<UpdateChartMutation>;
export type UpdateChartMutationOptions = Apollo.BaseMutationOptions<UpdateChartMutation, UpdateChartMutationVariables>;
