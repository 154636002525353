/* eslint-disable */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
export type GlobalTagsFieldsFragment = { __typename?: 'GlobalTags' } & {
    tags?: Types.Maybe<
        Array<
            { __typename?: 'TagAssociation' } & {
                tag: { __typename?: 'Tag' } & Pick<Types.Tag, 'urn' | 'name' | 'description'>;
            }
        >
    >;
};

export type GlossaryTermsFragment = { __typename?: 'GlossaryTerms' } & {
    terms?: Types.Maybe<
        Array<
            { __typename?: 'GlossaryTermAssociation' } & {
                term: { __typename?: 'GlossaryTerm' } & Pick<Types.GlossaryTerm, 'urn' | 'name'>;
            }
        >
    >;
};

export type OwnershipFieldsFragment = { __typename?: 'Ownership' } & {
    owners?: Types.Maybe<
        Array<
            { __typename?: 'Owner' } & Pick<Types.Owner, 'type'> & {
                    owner:
                        | ({ __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'type' | 'username'> & {
                                  info?: Types.Maybe<
                                      { __typename?: 'CorpUserInfo' } & Pick<
                                          Types.CorpUserInfo,
                                          | 'active'
                                          | 'displayName'
                                          | 'title'
                                          | 'email'
                                          | 'firstName'
                                          | 'lastName'
                                          | 'fullName'
                                      >
                                  >;
                                  editableInfo?: Types.Maybe<
                                      { __typename?: 'CorpUserEditableInfo' } & Pick<
                                          Types.CorpUserEditableInfo,
                                          'pictureLink'
                                      >
                                  >;
                              })
                        | ({ __typename?: 'CorpGroup' } & Pick<Types.CorpGroup, 'urn' | 'type' | 'name'> & {
                                  info?: Types.Maybe<
                                      { __typename?: 'CorpGroupInfo' } & Pick<
                                          Types.CorpGroupInfo,
                                          'email' | 'groups'
                                      > & {
                                              admins?: Types.Maybe<
                                                  Array<
                                                      { __typename?: 'CorpUser' } & Pick<
                                                          Types.CorpUser,
                                                          'urn' | 'username'
                                                      > & {
                                                              info?: Types.Maybe<
                                                                  { __typename?: 'CorpUserInfo' } & Pick<
                                                                      Types.CorpUserInfo,
                                                                      | 'active'
                                                                      | 'displayName'
                                                                      | 'title'
                                                                      | 'email'
                                                                      | 'firstName'
                                                                      | 'lastName'
                                                                      | 'fullName'
                                                                  >
                                                              >;
                                                              editableInfo?: Types.Maybe<
                                                                  { __typename?: 'CorpUserEditableInfo' } & Pick<
                                                                      Types.CorpUserEditableInfo,
                                                                      'pictureLink' | 'teams' | 'skills'
                                                                  >
                                                              >;
                                                          }
                                                  >
                                              >;
                                              members?: Types.Maybe<
                                                  Array<
                                                      { __typename?: 'CorpUser' } & Pick<
                                                          Types.CorpUser,
                                                          'urn' | 'username'
                                                      > & {
                                                              info?: Types.Maybe<
                                                                  { __typename?: 'CorpUserInfo' } & Pick<
                                                                      Types.CorpUserInfo,
                                                                      | 'active'
                                                                      | 'displayName'
                                                                      | 'title'
                                                                      | 'email'
                                                                      | 'firstName'
                                                                      | 'lastName'
                                                                      | 'fullName'
                                                                  >
                                                              >;
                                                              editableInfo?: Types.Maybe<
                                                                  { __typename?: 'CorpUserEditableInfo' } & Pick<
                                                                      Types.CorpUserEditableInfo,
                                                                      'pictureLink' | 'teams' | 'skills'
                                                                  >
                                                              >;
                                                          }
                                                  >
                                              >;
                                          }
                                  >;
                              });
                }
        >
    >;
    lastModified: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'>;
};

export type NonRecursiveDatasetFieldsFragment = { __typename?: 'Dataset' } & Pick<
    Types.Dataset,
    'urn' | 'name' | 'type' | 'origin' | 'description' | 'uri' | 'platformNativeType' | 'tags'
> & {
        platform: { __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'name'> & {
                info?: Types.Maybe<{ __typename?: 'DataPlatformInfo' } & Pick<Types.DataPlatformInfo, 'logoUrl'>>;
            };
        properties?: Types.Maybe<
            Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        institutionalMemory?: Types.Maybe<
            { __typename?: 'InstitutionalMemory' } & {
                elements: Array<
                    { __typename?: 'InstitutionalMemoryMetadata' } & Pick<
                        Types.InstitutionalMemoryMetadata,
                        'url' | 'description'
                    > & {
                            author: { __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'username'>;
                            created: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'actor' | 'time'>;
                        }
                >;
            }
        >;
        schema?: Types.Maybe<
            { __typename?: 'Schema' } & Pick<
                Types.Schema,
                'datasetUrn' | 'name' | 'platformUrn' | 'version' | 'hash' | 'primaryKeys'
            > & {
                    platformSchema?: Types.Maybe<
                        | ({ __typename?: 'TableSchema' } & Pick<Types.TableSchema, 'schema'>)
                        | ({ __typename?: 'KeyValueSchema' } & Pick<Types.KeyValueSchema, 'keySchema' | 'valueSchema'>)
                    >;
                    fields: Array<
                        { __typename?: 'SchemaField' } & Pick<
                            Types.SchemaField,
                            | 'fieldPath'
                            | 'jsonPath'
                            | 'nullable'
                            | 'description'
                            | 'type'
                            | 'nativeDataType'
                            | 'recursive'
                        > & {
                                globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                                glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
                            }
                    >;
                }
        >;
        editableSchemaMetadata?: Types.Maybe<
            { __typename?: 'EditableSchemaMetadata' } & {
                editableSchemaFieldInfo: Array<
                    { __typename?: 'EditableSchemaFieldInfo' } & Pick<
                        Types.EditableSchemaFieldInfo,
                        'fieldPath' | 'description'
                    > & { globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment> }
                >;
            }
        >;
        deprecation?: Types.Maybe<
            { __typename?: 'DatasetDeprecation' } & Pick<
                Types.DatasetDeprecation,
                'actor' | 'deprecated' | 'note' | 'decommissionTime'
            >
        >;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
    };

export type NonRecursiveDataFlowFieldsFragment = { __typename?: 'DataFlow' } & Pick<
    Types.DataFlow,
    'urn' | 'type' | 'orchestrator' | 'flowId' | 'cluster'
> & {
        info?: Types.Maybe<
            { __typename?: 'DataFlowInfo' } & Pick<
                Types.DataFlowInfo,
                'name' | 'description' | 'project' | 'externalUrl'
            > & {
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
    };

export type NonRecursiveDataJobFieldsFragment = { __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn'> & {
        info?: Types.Maybe<
            { __typename?: 'DataJobInfo' } & Pick<Types.DataJobInfo, 'name' | 'description' | 'externalUrl'> & {
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                }
        >;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
    };

export type DataJobFieldsFragment = { __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type' | 'jobId'> & {
        dataFlow?: Types.Maybe<{ __typename?: 'DataFlow' } & NonRecursiveDataFlowFieldsFragment>;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        inputOutput?: Types.Maybe<
            { __typename?: 'DataJobInputOutput' } & {
                inputDatasets?: Types.Maybe<Array<{ __typename?: 'Dataset' } & NonRecursiveDatasetFieldsFragment>>;
                outputDatasets?: Types.Maybe<Array<{ __typename?: 'Dataset' } & NonRecursiveDatasetFieldsFragment>>;
                inputDatajobs?: Types.Maybe<Array<{ __typename?: 'DataJob' } & NonRecursiveDataJobFieldsFragment>>;
            }
        >;
        info?: Types.Maybe<
            { __typename?: 'DataJobInfo' } & Pick<Types.DataJobInfo, 'name' | 'description' | 'externalUrl'> & {
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                }
        >;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
    };

export type DashboardFieldsFragment = { __typename?: 'Dashboard' } & Pick<
    Types.Dashboard,
    'urn' | 'type' | 'tool' | 'dashboardId'
> & {
        info?: Types.Maybe<
            { __typename?: 'DashboardInfo' } & Pick<
                Types.DashboardInfo,
                'name' | 'description' | 'externalUrl' | 'access' | 'lastRefreshed'
            > & {
                    charts: Array<
                        { __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'tool' | 'type'> & {
                                info?: Types.Maybe<
                                    { __typename?: 'ChartInfo' } & Pick<Types.ChartInfo, 'name' | 'description'> & {
                                            inputs?: Types.Maybe<
                                                Array<{ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn'>>
                                            >;
                                        }
                                >;
                                ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                            }
                    >;
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                    created: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'>;
                    lastModified: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'>;
                }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
    };

export const OwnershipFieldsFragmentDoc = gql`
    fragment ownershipFields on Ownership {
        owners {
            owner {
                ... on CorpUser {
                    urn
                    type
                    username
                    info {
                        active
                        displayName
                        title
                        email
                        firstName
                        lastName
                        fullName
                    }
                    editableInfo {
                        pictureLink
                    }
                }
                ... on CorpGroup {
                    urn
                    type
                    name
                    info {
                        email
                        admins {
                            urn
                            username
                            info {
                                active
                                displayName
                                title
                                email
                                firstName
                                lastName
                                fullName
                            }
                            editableInfo {
                                pictureLink
                                teams
                                skills
                            }
                        }
                        members {
                            urn
                            username
                            info {
                                active
                                displayName
                                title
                                email
                                firstName
                                lastName
                                fullName
                            }
                            editableInfo {
                                pictureLink
                                teams
                                skills
                            }
                        }
                        groups
                    }
                }
            }
            type
        }
        lastModified {
            time
        }
    }
`;
export const NonRecursiveDataFlowFieldsFragmentDoc = gql`
    fragment nonRecursiveDataFlowFields on DataFlow {
        urn
        type
        orchestrator
        flowId
        cluster
        info {
            name
            description
            project
            externalUrl
            customProperties {
                key
                value
            }
        }
        ownership {
            ...ownershipFields
        }
    }
    ${OwnershipFieldsFragmentDoc}
`;
export const GlobalTagsFieldsFragmentDoc = gql`
    fragment globalTagsFields on GlobalTags {
        tags {
            tag {
                urn
                name
                description
            }
        }
    }
`;
export const GlossaryTermsFragmentDoc = gql`
    fragment glossaryTerms on GlossaryTerms {
        terms {
            term {
                urn
                name
            }
        }
    }
`;
export const NonRecursiveDatasetFieldsFragmentDoc = gql`
    fragment nonRecursiveDatasetFields on Dataset {
        urn
        name
        type
        origin
        description
        uri
        platform {
            name
            info {
                logoUrl
            }
        }
        platformNativeType
        tags
        properties {
            key
            value
        }
        ownership {
            ...ownershipFields
        }
        institutionalMemory {
            elements {
                url
                author {
                    urn
                    username
                }
                description
                created {
                    actor
                    time
                }
            }
        }
        schema {
            datasetUrn
            name
            platformUrn
            version
            hash
            platformSchema {
                ... on TableSchema {
                    schema
                }
                ... on KeyValueSchema {
                    keySchema
                    valueSchema
                }
            }
            fields {
                fieldPath
                jsonPath
                nullable
                description
                type
                nativeDataType
                recursive
                globalTags {
                    ...globalTagsFields
                }
                glossaryTerms {
                    ...glossaryTerms
                }
            }
            primaryKeys
        }
        editableSchemaMetadata {
            editableSchemaFieldInfo {
                fieldPath
                description
                globalTags {
                    ...globalTagsFields
                }
            }
        }
        deprecation {
            actor
            deprecated
            note
            decommissionTime
        }
        globalTags {
            ...globalTagsFields
        }
        glossaryTerms {
            ...glossaryTerms
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
`;
export const NonRecursiveDataJobFieldsFragmentDoc = gql`
    fragment nonRecursiveDataJobFields on DataJob {
        urn
        info {
            name
            description
            externalUrl
            customProperties {
                key
                value
            }
        }
        globalTags {
            ...globalTagsFields
        }
    }
    ${GlobalTagsFieldsFragmentDoc}
`;
export const DataJobFieldsFragmentDoc = gql`
    fragment dataJobFields on DataJob {
        urn
        type
        dataFlow {
            ...nonRecursiveDataFlowFields
        }
        jobId
        ownership {
            ...ownershipFields
        }
        inputOutput {
            inputDatasets {
                ...nonRecursiveDatasetFields
            }
            outputDatasets {
                ...nonRecursiveDatasetFields
            }
            inputDatajobs {
                ...nonRecursiveDataJobFields
            }
        }
        info {
            name
            description
            externalUrl
            customProperties {
                key
                value
            }
        }
        globalTags {
            ...globalTagsFields
        }
    }
    ${NonRecursiveDataFlowFieldsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
    ${NonRecursiveDatasetFieldsFragmentDoc}
    ${NonRecursiveDataJobFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
`;
export const DashboardFieldsFragmentDoc = gql`
    fragment dashboardFields on Dashboard {
        urn
        type
        tool
        dashboardId
        info {
            name
            description
            charts {
                urn
                tool
                type
                info {
                    name
                    description
                    inputs {
                        urn
                    }
                }
                ownership {
                    ...ownershipFields
                }
            }
            customProperties {
                key
                value
            }
            externalUrl
            access
            lastRefreshed
            created {
                time
            }
            lastModified {
                time
            }
        }
        ownership {
            ...ownershipFields
        }
        globalTags {
            ...globalTagsFields
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
`;
