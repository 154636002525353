/* eslint-disable */
import * as Types from '../types.generated';

import {
    OwnershipFieldsFragment,
    GlobalTagsFieldsFragment,
    DataJobFieldsFragment,
    GlossaryTermsFragment,
    NonRecursiveDataFlowFieldsFragment,
    NonRecursiveDatasetFieldsFragment,
} from './fragments.generated';
import {
    ShallowLineageFields_Chart_Fragment,
    ShallowLineageFields_Dashboard_Fragment,
    ShallowLineageFields_DataJob_Fragment,
    ShallowLineageFields_Dataset_Fragment,
} from './genericLineage.generated';
import { gql } from '@apollo/client';
import {
    OwnershipFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    DataJobFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
    NonRecursiveDatasetFieldsFragmentDoc,
} from './fragments.generated';
import { ShallowLineageFieldsFragmentDoc } from './genericLineage.generated';
import * as Apollo from '@apollo/client';
export type DataFlowFieldsFragment = { __typename?: 'DataFlow' } & Pick<
    Types.DataFlow,
    'urn' | 'type' | 'orchestrator' | 'flowId' | 'cluster'
> & {
        info?: Types.Maybe<
            { __typename?: 'DataFlowInfo' } & Pick<
                Types.DataFlowInfo,
                'name' | 'description' | 'project' | 'externalUrl'
            > & {
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
    };

export type GetDataFlowQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetDataFlowQuery = { __typename?: 'Query' } & {
    dataFlow?: Types.Maybe<
        { __typename?: 'DataFlow' } & {
            dataJobs?: Types.Maybe<
                { __typename?: 'DataFlowDataJobsRelationships' } & {
                    entities?: Types.Maybe<
                        Array<
                            Types.Maybe<
                                { __typename?: 'EntityRelationship' } & {
                                    entity?: Types.Maybe<
                                        | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                                        | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                                        | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'> &
                                              DataJobFieldsFragment &
                                              ShallowLineageFields_DataJob_Fragment)
                                        | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                                    >;
                                }
                            >
                        >
                    >;
                }
            >;
        } & DataFlowFieldsFragment
    >;
};

export type UpdateDataFlowMutationVariables = Types.Exact<{
    input: Types.DataFlowUpdateInput;
}>;

export type UpdateDataFlowMutation = { __typename?: 'Mutation' } & {
    updateDataFlow?: Types.Maybe<
        { __typename?: 'DataFlow' } & {
            dataJobs?: Types.Maybe<
                { __typename?: 'DataFlowDataJobsRelationships' } & {
                    entities?: Types.Maybe<
                        Array<
                            Types.Maybe<
                                { __typename?: 'EntityRelationship' } & {
                                    entity?: Types.Maybe<
                                        | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                                        | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                                        | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'> &
                                              DataJobFieldsFragment &
                                              ShallowLineageFields_DataJob_Fragment)
                                        | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                                    >;
                                }
                            >
                        >
                    >;
                }
            >;
        } & DataFlowFieldsFragment
    >;
};

export const DataFlowFieldsFragmentDoc = gql`
    fragment dataFlowFields on DataFlow {
        urn
        type
        orchestrator
        flowId
        cluster
        info {
            name
            description
            project
            externalUrl
            customProperties {
                key
                value
            }
        }
        ownership {
            ...ownershipFields
        }
        globalTags {
            ...globalTagsFields
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
`;
export const GetDataFlowDocument = gql`
    query getDataFlow($urn: String!) {
        dataFlow(urn: $urn) {
            ...dataFlowFields
            dataJobs {
                entities {
                    entity {
                        urn
                        type
                        ... on DataJob {
                            ...dataJobFields
                            ...shallowLineageFields
                        }
                    }
                }
            }
        }
    }
    ${DataFlowFieldsFragmentDoc}
    ${DataJobFieldsFragmentDoc}
    ${ShallowLineageFieldsFragmentDoc}
`;

/**
 * __useGetDataFlowQuery__
 *
 * To run a query within a React component, call `useGetDataFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataFlowQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetDataFlowQuery(baseOptions: Apollo.QueryHookOptions<GetDataFlowQuery, GetDataFlowQueryVariables>) {
    return Apollo.useQuery<GetDataFlowQuery, GetDataFlowQueryVariables>(GetDataFlowDocument, baseOptions);
}
export function useGetDataFlowLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDataFlowQuery, GetDataFlowQueryVariables>,
) {
    return Apollo.useLazyQuery<GetDataFlowQuery, GetDataFlowQueryVariables>(GetDataFlowDocument, baseOptions);
}
export type GetDataFlowQueryHookResult = ReturnType<typeof useGetDataFlowQuery>;
export type GetDataFlowLazyQueryHookResult = ReturnType<typeof useGetDataFlowLazyQuery>;
export type GetDataFlowQueryResult = Apollo.QueryResult<GetDataFlowQuery, GetDataFlowQueryVariables>;
export const UpdateDataFlowDocument = gql`
    mutation updateDataFlow($input: DataFlowUpdateInput!) {
        updateDataFlow(input: $input) {
            ...dataFlowFields
            dataJobs {
                entities {
                    entity {
                        urn
                        type
                        ... on DataJob {
                            ...dataJobFields
                            ...shallowLineageFields
                        }
                    }
                }
            }
        }
    }
    ${DataFlowFieldsFragmentDoc}
    ${DataJobFieldsFragmentDoc}
    ${ShallowLineageFieldsFragmentDoc}
`;
export type UpdateDataFlowMutationFn = Apollo.MutationFunction<UpdateDataFlowMutation, UpdateDataFlowMutationVariables>;

/**
 * __useUpdateDataFlowMutation__
 *
 * To run a mutation, you first call `useUpdateDataFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataFlowMutation, { data, loading, error }] = useUpdateDataFlowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataFlowMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateDataFlowMutation, UpdateDataFlowMutationVariables>,
) {
    return Apollo.useMutation<UpdateDataFlowMutation, UpdateDataFlowMutationVariables>(
        UpdateDataFlowDocument,
        baseOptions,
    );
}
export type UpdateDataFlowMutationHookResult = ReturnType<typeof useUpdateDataFlowMutation>;
export type UpdateDataFlowMutationResult = Apollo.MutationResult<UpdateDataFlowMutation>;
export type UpdateDataFlowMutationOptions = Apollo.BaseMutationOptions<
    UpdateDataFlowMutation,
    UpdateDataFlowMutationVariables
>;
