export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Long: number;
};

export enum AccessLevel {
    /** Publicly available */
    Public = 'PUBLIC',
    /** Restricted to a subset of viewers */
    Private = 'PRIVATE',
}

export type AggregationMetadata = {
    __typename?: 'AggregationMetadata';
    /** A particular value of a facet field */
    value: Scalars['String'];
    /** The number of search results containing the value */
    count: Scalars['Long'];
};

export type AnalyticsChart = TimeSeriesChart | BarChart | TableChart;

export type AnalyticsChartGroup = {
    __typename?: 'AnalyticsChartGroup';
    title: Scalars['String'];
    charts: Array<AnalyticsChart>;
};

export type AuditStamp = {
    __typename?: 'AuditStamp';
    /** When the audited action took place */
    time: Scalars['Long'];
    /** Who performed the audited action */
    actor?: Maybe<Scalars['String']>;
};

export type AutoCompleteAllResults = {
    __typename?: 'AutoCompleteAllResults';
    /** The query string */
    query: Scalars['String'];
    /** The autocompletion results */
    suggestions: Array<AutoCompleteResultForEntity>;
};

export type AutoCompleteInput = {
    /** Entity type to be autocompleted against */
    type?: Maybe<EntityType>;
    /** The query string */
    query: Scalars['String'];
    /** An optional field to autocomplete against */
    field?: Maybe<Scalars['String']>;
    /** The maximum number of autocomplete results */
    limit?: Maybe<Scalars['Int']>;
    /** Faceted filters applied to autocomplete results */
    filters?: Maybe<Array<FacetFilterInput>>;
};

export type AutoCompleteResultForEntity = {
    __typename?: 'AutoCompleteResultForEntity';
    /** Entity type */
    type: EntityType;
    /** The autocompletion results for specified entity type */
    suggestions: Array<Scalars['String']>;
};

export type AutoCompleteResults = {
    __typename?: 'AutoCompleteResults';
    /** The query string */
    query: Scalars['String'];
    /** The autocompletion results */
    suggestions: Array<Scalars['String']>;
};

export type BarChart = {
    __typename?: 'BarChart';
    title: Scalars['String'];
    bars: Array<NamedBar>;
};

export type BarSegment = {
    __typename?: 'BarSegment';
    label: Scalars['String'];
    value: Scalars['Int'];
};

export type BaseData = {
    __typename?: 'BaseData';
    /** Dataset used for the Training/Evaluation of the MLModel */
    dataset: Scalars['String'];
    /** Motivation to pick these datasets */
    motivation?: Maybe<Scalars['String']>;
    /** Details of Data Proprocessing */
    preProcessing?: Maybe<Array<Scalars['String']>>;
};

export type BooleanBox = {
    __typename?: 'BooleanBox';
    booleanValue: Scalars['Boolean'];
};

export type BrowseInput = {
    /** The browse entity type */
    type: EntityType;
    /** The browse path.. Should this be an array? */
    path?: Maybe<Array<Scalars['String']>>;
    /** The starting point of paginated results */
    start?: Maybe<Scalars['Int']>;
    /** The number of elements included in the results */
    count?: Maybe<Scalars['Int']>;
    /** Faceted filters applied to browse results */
    filters?: Maybe<Array<FacetFilterInput>>;
};

export type BrowsePath = {
    __typename?: 'BrowsePath';
    /** The components of the browse path */
    path: Array<Scalars['String']>;
};

export type BrowsePathsInput = {
    /** The browse entity type */
    type: EntityType;
    /** The entity urn */
    urn: Scalars['String'];
};

export type BrowseResultGroup = {
    __typename?: 'BrowseResultGroup';
    /** The path name of a group of browse results */
    name: Scalars['String'];
    /** The number of entities within the group */
    count: Scalars['Long'];
};

export type BrowseResultMetadata = {
    __typename?: 'BrowseResultMetadata';
    /** The provided path */
    path: Array<Scalars['String']>;
    /** The groups present at the provided browse path */
    groups: Array<BrowseResultGroup>;
    /** The total number of entities under the provided browse path */
    totalNumEntities: Scalars['Long'];
};

export type BrowseResults = {
    __typename?: 'BrowseResults';
    /** The browse results */
    entities: Array<Entity>;
    /** The starting point of paginated results */
    start: Scalars['Int'];
    /** The number of elements included in the results */
    count: Scalars['Int'];
    /** The total number of browse results under the path with filters applied */
    total: Scalars['Int'];
    /** Metadata containing resulting browse groups */
    metadata: BrowseResultMetadata;
};

export type CaveatDetails = {
    __typename?: 'CaveatDetails';
    /** Did the results suggest any further testing? */
    needsFurtherTesting?: Maybe<Scalars['Boolean']>;
    /** Caveat Description */
    caveatDescription?: Maybe<Scalars['String']>;
    /** Relevant groups that were not represented in the evaluation dataset? */
    groupsNotRepresented?: Maybe<Array<Scalars['String']>>;
};

export type CaveatsAndRecommendations = {
    __typename?: 'CaveatsAndRecommendations';
    /** Caveats on using this MLModel */
    caveats?: Maybe<CaveatDetails>;
    /** Recommendations on where this MLModel should be used. */
    recommendations?: Maybe<Scalars['String']>;
    /** Ideal characteristics of an evaluation dataset for this MLModel */
    idealDatasetCharacteristics?: Maybe<Array<Scalars['String']>>;
};

export type Chart = EntityWithRelationships &
    Entity & {
        __typename?: 'Chart';
        /** The unique user URN */
        urn: Scalars['String'];
        /** GMS Entity Type */
        type: EntityType;
        /** The chart tool name */
        tool: Scalars['String'];
        /** An id unique within the charting tool */
        chartId: Scalars['String'];
        /** Info about the chart */
        info?: Maybe<ChartInfo>;
        /** Info about the query which is used to render the chart */
        query?: Maybe<ChartQuery>;
        /** Ownership metadata of the chart */
        ownership?: Maybe<Ownership>;
        /** Status metadata of the chart */
        status?: Maybe<Status>;
        /** The structured tags associated with the chart */
        globalTags?: Maybe<GlobalTags>;
        /** Entities upstream of the given entity */
        upstreamLineage?: Maybe<UpstreamEntityRelationships>;
        /** Entities downstream of the given entity */
        downstreamLineage?: Maybe<DownstreamEntityRelationships>;
    };

export type ChartInfo = {
    __typename?: 'ChartInfo';
    /** Name of the chart */
    name: Scalars['String'];
    /** Description of the chart */
    description?: Maybe<Scalars['String']>;
    /** Data sources for the chart */
    inputs?: Maybe<Array<Dataset>>;
    /** Native platform URL of the chart */
    externalUrl?: Maybe<Scalars['String']>;
    /** Access level for the chart */
    type?: Maybe<ChartType>;
    /** Access level for the chart */
    access?: Maybe<AccessLevel>;
    /** A list of platform-specific metadata tuples */
    customProperties?: Maybe<Array<StringMapEntry>>;
    /** The time when this chart last refreshed */
    lastRefreshed?: Maybe<Scalars['Long']>;
    /** An AuditStamp corresponding to the creation of this chart */
    created: AuditStamp;
    /** An AuditStamp corresponding to the modification of this chart */
    lastModified: AuditStamp;
    /** An optional AuditStamp corresponding to the deletion of this chart */
    deleted?: Maybe<AuditStamp>;
};

export type ChartQuery = {
    __typename?: 'ChartQuery';
    /** Raw query to build a chart from input datasets */
    rawQuery: Scalars['String'];
    /** The type of the chart query */
    type: ChartQueryType;
};

export enum ChartQueryType {
    /** Standard ANSI SQL */
    Sql = 'SQL',
    /** LookML */
    Lookml = 'LOOKML',
}

export enum ChartType {
    /** Bar graph */
    Bar = 'BAR',
    /** Pie chart */
    Pie = 'PIE',
    /** Scatter plot */
    Scatter = 'SCATTER',
    /** Table */
    Table = 'TABLE',
    /** Markdown formatted text */
    Text = 'TEXT',
    Line = 'LINE',
    Area = 'AREA',
    Histogram = 'HISTOGRAM',
    BoxPlot = 'BOX_PLOT',
}

export type ChartUpdateInput = {
    /** The chart urn */
    urn: Scalars['String'];
    /** Update to ownership */
    ownership?: Maybe<OwnershipUpdate>;
    /** Update to global tags */
    globalTags?: Maybe<GlobalTagsUpdate>;
};

export type CorpGroup = Entity & {
    __typename?: 'CorpGroup';
    /** The unique user URN */
    urn: Scalars['String'];
    /** GMS Entity Type */
    type: EntityType;
    /** group name e.g. wherehows-dev, ask_metadata */
    name?: Maybe<Scalars['String']>;
    /** Information of the corp group */
    info?: Maybe<CorpGroupInfo>;
};

export type CorpGroupInfo = {
    __typename?: 'CorpGroupInfo';
    /** email of this group */
    email: Scalars['String'];
    /** owners of this group */
    admins?: Maybe<Array<CorpUser>>;
    /** List of ldap urn in this group. */
    members?: Maybe<Array<CorpUser>>;
    /** List of groups in this group. */
    groups?: Maybe<Array<Scalars['String']>>;
};

export type CorpUser = Entity & {
    __typename?: 'CorpUser';
    /** The unique user URN */
    urn: Scalars['String'];
    /** GMS Entity Type */
    type: EntityType;
    /** Username of the corp user */
    username: Scalars['String'];
    /** Readable info about the corp user */
    info?: Maybe<CorpUserInfo>;
    /** Writable info about the corp user */
    editableInfo?: Maybe<CorpUserEditableInfo>;
    /** The structured tags associated with the user */
    globalTags?: Maybe<GlobalTags>;
};

export type CorpUserEditableInfo = {
    __typename?: 'CorpUserEditableInfo';
    /** About me section of the user */
    aboutMe?: Maybe<Scalars['String']>;
    /** Teams that the user belongs to */
    teams?: Maybe<Array<Scalars['String']>>;
    /** Skills that the user possesses */
    skills?: Maybe<Array<Scalars['String']>>;
    /** A URL which points to a picture which user wants to set as a profile photo */
    pictureLink?: Maybe<Scalars['String']>;
};

export type CorpUserInfo = {
    __typename?: 'CorpUserInfo';
    /** Whether the user is active */
    active: Scalars['Boolean'];
    /** Display name of the user */
    displayName?: Maybe<Scalars['String']>;
    /** Email address of the user */
    email: Scalars['String'];
    /** Title of the user */
    title?: Maybe<Scalars['String']>;
    /** Direct manager of the user */
    manager?: Maybe<CorpUser>;
    /** department id the user belong to */
    departmentId?: Maybe<Scalars['Long']>;
    /** department name this user belong to */
    departmentName?: Maybe<Scalars['String']>;
    /** first name of the user */
    firstName?: Maybe<Scalars['String']>;
    /** last name of the user */
    lastName?: Maybe<Scalars['String']>;
    /** Common name of this user, format is firstName plus lastName */
    fullName?: Maybe<Scalars['String']>;
    /** two uppercase letters country code */
    countryCode?: Maybe<Scalars['String']>;
};

export type Cost = {
    __typename?: 'Cost';
    /** Type of Cost Code */
    costType: CostType;
    /** Code to which the Cost of this entity should be attributed to i.e. organizational cost ID */
    costValue: CostValue;
};

export enum CostType {
    /** Org Cost Type to which the Cost of this entity should be attributed to */
    OrgCostType = 'ORG_COST_TYPE',
}

export type CostValue = {
    __typename?: 'CostValue';
    /** Organizational Cost ID */
    costId?: Maybe<Scalars['Float']>;
    /** Organizational Cost Code */
    costCode?: Maybe<Scalars['String']>;
};

export type Dashboard = EntityWithRelationships &
    Entity & {
        __typename?: 'Dashboard';
        /** The unique dashboard URN */
        urn: Scalars['String'];
        /** GMS Entity Type */
        type: EntityType;
        /** The dashboard tool name */
        tool: Scalars['String'];
        /** An id unique within the dashboard tool */
        dashboardId: Scalars['String'];
        /** Info about the dashboard */
        info?: Maybe<DashboardInfo>;
        /** Ownership metadata of the dashboard */
        ownership?: Maybe<Ownership>;
        /** Status metadata of the dashboard */
        status?: Maybe<Status>;
        /** The structured tags associated with the dashboard */
        globalTags?: Maybe<GlobalTags>;
        /** Entities upstream of the given entity */
        upstreamLineage?: Maybe<UpstreamEntityRelationships>;
        /** Entities downstream of the given entity */
        downstreamLineage?: Maybe<DownstreamEntityRelationships>;
    };

export type DashboardInfo = {
    __typename?: 'DashboardInfo';
    /** Name of the dashboard */
    name: Scalars['String'];
    /** Description of the dashboard */
    description?: Maybe<Scalars['String']>;
    /** Charts that comprise the dashboard */
    charts: Array<Chart>;
    /** Native platform URL of the dashboard */
    externalUrl?: Maybe<Scalars['String']>;
    /** Access level for the dashboard */
    access?: Maybe<AccessLevel>;
    /** A list of platform-specific metadata tuples */
    customProperties?: Maybe<Array<StringMapEntry>>;
    /** The time when this dashboard last refreshed */
    lastRefreshed?: Maybe<Scalars['Long']>;
    /** An AuditStamp corresponding to the creation of this dashboard */
    created: AuditStamp;
    /** An AuditStamp corresponding to the modification of this dashboard */
    lastModified: AuditStamp;
    /** An optional AuditStamp corresponding to the deletion of this dashboard */
    deleted?: Maybe<AuditStamp>;
};

export type DashboardUpdateInput = {
    /** The dashboard urn */
    urn: Scalars['String'];
    /** Update to ownership */
    ownership?: Maybe<OwnershipUpdate>;
    /** Update to global tags */
    globalTags?: Maybe<GlobalTagsUpdate>;
};

export type DataFlow = Entity & {
    __typename?: 'DataFlow';
    /** The unique flow URN */
    urn: Scalars['String'];
    /** GMS Entity Type */
    type: EntityType;
    /** Workflow orchestrator ex: Azkaban, Airflow */
    orchestrator: Scalars['String'];
    /** Id of the flow */
    flowId: Scalars['String'];
    /** Cluster of the flow */
    cluster: Scalars['String'];
    /** Information about a Data processing flow */
    info?: Maybe<DataFlowInfo>;
    /** Ownership metadata of the flow */
    ownership?: Maybe<Ownership>;
    /** The structured tags associated with the dataflow */
    globalTags?: Maybe<GlobalTags>;
    /** Status metadata of the dataflow */
    status?: Maybe<Status>;
    /** DataJobs */
    dataJobs?: Maybe<DataFlowDataJobsRelationships>;
};

export type DataFlowDataJobsRelationships = {
    __typename?: 'DataFlowDataJobsRelationships';
    entities?: Maybe<Array<Maybe<EntityRelationship>>>;
};

export type DataFlowInfo = {
    __typename?: 'DataFlowInfo';
    /** Name of the flow */
    name: Scalars['String'];
    /** Description of the flow */
    description?: Maybe<Scalars['String']>;
    /** Optional project/namespace associated with the flow */
    project?: Maybe<Scalars['String']>;
    /** External URL associated with the DataFlow */
    externalUrl?: Maybe<Scalars['String']>;
    /** A list of platform-specific metadata tuples */
    customProperties?: Maybe<Array<StringMapEntry>>;
};

export type DataFlowUpdateInput = {
    /** The dataFlow urn */
    urn: Scalars['String'];
    /** Update to ownership */
    ownership?: Maybe<OwnershipUpdate>;
    /** Update to global tags */
    globalTags?: Maybe<GlobalTagsUpdate>;
};

export type DataJob = EntityWithRelationships &
    Entity & {
        __typename?: 'DataJob';
        /** The unique job URN */
        urn: Scalars['String'];
        /** GMS Entity Type */
        type: EntityType;
        /** The associated data flow */
        dataFlow?: Maybe<DataFlow>;
        /** Id of the job */
        jobId: Scalars['String'];
        /** Information about a Data processing job */
        info?: Maybe<DataJobInfo>;
        /** Ownership metadata of the job */
        ownership?: Maybe<Ownership>;
        /** Information about the inputs and outputs of a Data processing job */
        inputOutput?: Maybe<DataJobInputOutput>;
        /** Entities upstream of the given entity */
        upstreamLineage?: Maybe<UpstreamEntityRelationships>;
        /** Entities downstream of the given entity */
        downstreamLineage?: Maybe<DownstreamEntityRelationships>;
        /** The structured tags associated with the DataJob */
        globalTags?: Maybe<GlobalTags>;
        /** Status metadata of the DataJob */
        status?: Maybe<Status>;
    };

export type DataJobInfo = {
    __typename?: 'DataJobInfo';
    /** Job name */
    name: Scalars['String'];
    /** Job description */
    description?: Maybe<Scalars['String']>;
    /** External URL associated with the DataJob */
    externalUrl?: Maybe<Scalars['String']>;
    /** A list of platform-specific metadata tuples */
    customProperties?: Maybe<Array<StringMapEntry>>;
};

export type DataJobInputOutput = {
    __typename?: 'DataJobInputOutput';
    /** Input datasets produced by the data job during processing */
    inputDatasets?: Maybe<Array<Dataset>>;
    /** Output datasets produced by the data job during processing */
    outputDatasets?: Maybe<Array<Dataset>>;
    /** Input datajobs that this data job depends on */
    inputDatajobs?: Maybe<Array<DataJob>>;
};

export type DataJobUpdateInput = {
    /** The dataJob urn */
    urn: Scalars['String'];
    /** Update to ownership */
    ownership?: Maybe<OwnershipUpdate>;
    /** Update to global tags */
    globalTags?: Maybe<GlobalTagsUpdate>;
};

export type DataPlatform = Entity & {
    __typename?: 'DataPlatform';
    /** Urn of the data platform */
    urn: Scalars['String'];
    /** GMS Entity Type */
    type: EntityType;
    /** Name of the data platform */
    name: Scalars['String'];
    /** Metadata associated with a dataplatform */
    info?: Maybe<DataPlatformInfo>;
};

export type DataPlatformInfo = {
    __typename?: 'DataPlatformInfo';
    /** The Platform Type */
    type: PlatformType;
    /** Display name associated with the platform */
    displayName?: Maybe<Scalars['String']>;
    /** The delimiter in the dataset names on the data platform */
    datasetNameDelimiter: Scalars['String'];
    /** A logo URL associated with the platform */
    logoUrl?: Maybe<Scalars['String']>;
};

export type Dataset = EntityWithRelationships &
    Entity & {
        __typename?: 'Dataset';
        /** The unique Dataset URN */
        urn: Scalars['String'];
        /** GMS Entity Type */
        type: EntityType;
        /** Standardized platform urn where the dataset is defined */
        platform: DataPlatform;
        /** Dataset native name */
        name: Scalars['String'];
        /** Fabric type where dataset belongs to or where it was generated */
        origin: FabricType;
        /** Human readable description for dataset */
        description?: Maybe<Scalars['String']>;
        /** The native format for the data platform */
        platformNativeType?: Maybe<PlatformNativeType>;
        /** Native Dataset Uri. Uri should not include any environment specific properties */
        uri?: Maybe<Scalars['String']>;
        /** Tags used for searching dataset */
        tags: Array<Scalars['String']>;
        /** A list of platform-specific metadata tuples */
        properties?: Maybe<Array<StringMapEntry>>;
        /** External URL associated with the Dataset */
        externalUrl?: Maybe<Scalars['String']>;
        /** Ownership metadata of the dataset */
        ownership?: Maybe<Ownership>;
        /** The dataset deprecation status */
        deprecation?: Maybe<DatasetDeprecation>;
        /** References to internal resources related to the dataset */
        institutionalMemory?: Maybe<InstitutionalMemory>;
        /** Schema metadata of the dataset */
        schema?: Maybe<Schema>;
        /** Editable schema metadata of the dataset */
        editableSchemaMetadata?: Maybe<EditableSchemaMetadata>;
        /** Status metadata of the dataset */
        status?: Maybe<Status>;
        /** Entities upstream of the given entity */
        upstreamLineage?: Maybe<UpstreamEntityRelationships>;
        /** Entities downstream of the given entity */
        downstreamLineage?: Maybe<DownstreamEntityRelationships>;
        /** The structured tags associated with the dataset */
        globalTags?: Maybe<GlobalTags>;
        /** The structured glossary terms associated with the dataset */
        glossaryTerms?: Maybe<GlossaryTerms>;
    };

export type DatasetDeprecation = {
    __typename?: 'DatasetDeprecation';
    /** Whether the dataset has been deprecated by owner */
    deprecated: Scalars['Boolean'];
    /** The time user plan to decommission this dataset */
    decommissionTime?: Maybe<Scalars['Long']>;
    /** Additional information about the dataset deprecation plan */
    note: Scalars['String'];
    /** The user who will be credited for modifying this deprecation content */
    actor?: Maybe<Scalars['String']>;
};

export type DatasetDeprecationUpdate = {
    /** Whether the dataset is deprecated */
    deprecated: Scalars['Boolean'];
    /** The time user plan to decommission this dataset */
    decommissionTime?: Maybe<Scalars['Long']>;
    /** Additional information about the dataset deprecation plan */
    note: Scalars['String'];
};

export enum DatasetLineageType {
    /** Direct copy without modification */
    Copy = 'COPY',
    /** Transformed dataset */
    Transformed = 'TRANSFORMED',
    /** Represents a view defined on the sources */
    View = 'VIEW',
}

export type DatasetUpdateInput = {
    /** The dataset urn */
    urn: Scalars['String'];
    /** Update to ownership */
    ownership?: Maybe<OwnershipUpdate>;
    /** Update to deprecation status */
    deprecation?: Maybe<DatasetDeprecationUpdate>;
    /** Update to institutional memory, ie documentation */
    institutionalMemory?: Maybe<InstitutionalMemoryUpdate>;
    /** Update to global tags */
    globalTags?: Maybe<GlobalTagsUpdate>;
    /** Update to editable schema metadata of the dataset */
    editableSchemaMetadata?: Maybe<EditableSchemaMetadataUpdate>;
};

export enum DateInterval {
    Second = 'SECOND',
    Minute = 'MINUTE',
    Hour = 'HOUR',
    Day = 'DAY',
    Week = 'WEEK',
    Month = 'MONTH',
    Year = 'YEAR',
}

export type DateRange = {
    __typename?: 'DateRange';
    start: Scalars['String'];
    end: Scalars['String'];
};

export type Deprecation = {
    __typename?: 'Deprecation';
    /** Whether the entity has been deprecated by owner */
    deprecated: Scalars['Boolean'];
    /** The time user plan to decommission this entity */
    decommissionTime?: Maybe<Scalars['Long']>;
    /** Additional information about the entity deprecation plan */
    note: Scalars['String'];
    /** The user who will be credited for modifying this deprecation content */
    actor?: Maybe<Scalars['String']>;
};

export type DownstreamEntityRelationships = {
    __typename?: 'DownstreamEntityRelationships';
    entities?: Maybe<Array<Maybe<EntityRelationship>>>;
};

export type DownstreamLineage = {
    __typename?: 'DownstreamLineage';
    /** List of downstream datasets */
    downstreams: Array<RelatedDataset>;
};

export type EditableSchemaFieldInfo = {
    __typename?: 'EditableSchemaFieldInfo';
    /** Flattened name of a field identifying the field the editable info is applied to */
    fieldPath: Scalars['String'];
    /** Edited description of the field */
    description?: Maybe<Scalars['String']>;
    /** Tags associated with the field */
    globalTags?: Maybe<GlobalTags>;
};

export type EditableSchemaFieldInfoUpdate = {
    /** Flattened name of a field identifying the field the editable info is applied to */
    fieldPath: Scalars['String'];
    /** Edited description of the field */
    description?: Maybe<Scalars['String']>;
    /** Tags associated with the field */
    globalTags?: Maybe<GlobalTagsUpdate>;
};

export type EditableSchemaMetadata = {
    __typename?: 'EditableSchemaMetadata';
    editableSchemaFieldInfo: Array<EditableSchemaFieldInfo>;
};

export type EditableSchemaMetadataUpdate = {
    editableSchemaFieldInfo: Array<EditableSchemaFieldInfoUpdate>;
};

/** Top-level GMS Entity */
export type Entity = {
    /** GMS Entity urn */
    urn: Scalars['String'];
    /** GMS Entity Type */
    type: EntityType;
};

export type EntityRelationship = {
    __typename?: 'EntityRelationship';
    /** An AuditStamp corresponding to the last modification of this relationship */
    created?: Maybe<AuditStamp>;
    /** Entity that is related via lineage */
    entity?: Maybe<EntityWithRelationships>;
};

/** Top-level GMS Entity Type */
export enum EntityType {
    /** The Dataset Entity */
    Dataset = 'DATASET',
    /** The CorpUser Entity */
    CorpUser = 'CORP_USER',
    /** The CorpGroup Entity */
    CorpGroup = 'CORP_GROUP',
    /** The DataPlatform Entity */
    DataPlatform = 'DATA_PLATFORM',
    /** The Dashboard Entity */
    Dashboard = 'DASHBOARD',
    /** The Chart Entity */
    Chart = 'CHART',
    /** The Tag Entity */
    Tag = 'TAG',
    /** The MLModel Entity */
    Mlmodel = 'MLMODEL',
    /** The DATA_FLOW Entity */
    DataFlow = 'DATA_FLOW',
    /** The DATA_JOB Entity */
    DataJob = 'DATA_JOB',
    /** The GlossaryTerm Entity */
    GlossaryTerm = 'GLOSSARY_TERM',
}

export type EntityWithRelationships = {
    /** GMS Entity urn */
    urn: Scalars['String'];
    /** GMS Entity Type */
    type: EntityType;
    /** Entities upstream of the given entity */
    upstreamLineage?: Maybe<UpstreamEntityRelationships>;
    /** Entities downstream of the given entity */
    downstreamLineage?: Maybe<DownstreamEntityRelationships>;
};

export type EthicalConsiderations = {
    __typename?: 'EthicalConsiderations';
    /** Does the model use any sensitive data (e.g., protected classes)? */
    data?: Maybe<Array<Scalars['String']>>;
    /** Is the model intended to inform decisions about matters central to human life or flourishing – e.g., health or safety? Or could it be used in such a way? */
    humanLife?: Maybe<Array<Scalars['String']>>;
    /** What risk mitigation strategies were used during model development? */
    mitigations?: Maybe<Array<Scalars['String']>>;
    /** What risks may be present in model usage? Try to identify the potential recipients, likelihood, and magnitude of harms. If these cannot be determined, note that they were considered but remain unknown. */
    risksAndHarms?: Maybe<Array<Scalars['String']>>;
    /** Are there any known model use cases that are especially fraught? This may connect directly to the intended use section */
    useCases?: Maybe<Array<Scalars['String']>>;
};

export enum FabricType {
    /** Designates development fabrics */
    Dev = 'DEV',
    /** Designates early-integration (staging) fabrics */
    Ei = 'EI',
    /** Designates production fabrics */
    Prod = 'PROD',
    /** Designates corporation fabrics */
    Corp = 'CORP',
}

export type FacetFilterInput = {
    /** Name of field to filter by */
    field: Scalars['String'];
    /** Value of the field to filter by */
    value: Scalars['String'];
};

export type FacetMetadata = {
    __typename?: 'FacetMetadata';
    /** Name of a field present in the search entity */
    field: Scalars['String'];
    /** Aggregated search result counts by value of the field */
    aggregations: Array<AggregationMetadata>;
};

export type FloatBox = {
    __typename?: 'FloatBox';
    floatValue: Scalars['Float'];
};

export type GlobalTags = {
    __typename?: 'GlobalTags';
    tags?: Maybe<Array<TagAssociation>>;
};

export type GlobalTagsUpdate = {
    tags?: Maybe<Array<TagAssociationUpdate>>;
};

export type GlossaryTerm = Entity & {
    __typename?: 'GlossaryTerm';
    /** Urn of the data platform */
    urn: Scalars['String'];
    /** Ownership metadata of the dataset */
    ownership?: Maybe<Ownership>;
    /** GMS Entity Type */
    type: EntityType;
    /** Name of the data platform */
    name: Scalars['String'];
    /** Details of the Glossary Term */
    glossaryTermInfo: GlossaryTermInfo;
};

export type GlossaryTermAssociation = {
    __typename?: 'GlossaryTermAssociation';
    term: GlossaryTerm;
};

export type GlossaryTermInfo = {
    __typename?: 'GlossaryTermInfo';
    /** Name of the glossary term */
    definition: Scalars['String'];
    /** Term Source of the glossary term */
    termSource: Scalars['String'];
    /** Source Ref of the glossary term */
    sourceRef?: Maybe<Scalars['String']>;
    /** Source Url of the glossary term */
    sourceUrl?: Maybe<Scalars['String']>;
    /** Properties of the glossary term */
    customProperties?: Maybe<Array<StringMapEntry>>;
};

export type GlossaryTerms = {
    __typename?: 'GlossaryTerms';
    terms?: Maybe<Array<GlossaryTermAssociation>>;
};

export type Highlight = {
    __typename?: 'Highlight';
    value: Scalars['Int'];
    title: Scalars['String'];
    body: Scalars['String'];
};

export type HyperParameterMap = {
    __typename?: 'HyperParameterMap';
    key: Scalars['String'];
    value: HyperParameterValueType;
};

export type HyperParameterValueType = StringBox | IntBox | FloatBox | BooleanBox;

export type InstitutionalMemory = {
    __typename?: 'InstitutionalMemory';
    /** List of records that represent the institutional memory (internal documentation) of an entity */
    elements: Array<InstitutionalMemoryMetadata>;
};

export type InstitutionalMemoryMetadata = {
    __typename?: 'InstitutionalMemoryMetadata';
    /** Link to a document or wiki page or another internal resource */
    url: Scalars['String'];
    /** Description of the resource */
    description: Scalars['String'];
    /** The author of this metadata */
    author: CorpUser;
    /** An AuditStamp corresponding to the creation of this resource */
    created: AuditStamp;
};

export type InstitutionalMemoryMetadataUpdate = {
    /** Link to a document or wiki page or another internal resource */
    url: Scalars['String'];
    /** Description of the resource */
    description?: Maybe<Scalars['String']>;
    /** The corp user urn of the author of the metadata */
    author: Scalars['String'];
    /** The time at which this metadata was created */
    createdAt?: Maybe<Scalars['Long']>;
};

export type InstitutionalMemoryUpdate = {
    /** The individual references in the institutional memory */
    elements: Array<InstitutionalMemoryMetadataUpdate>;
};

export type IntBox = {
    __typename?: 'IntBox';
    intValue: Scalars['Int'];
};

export type IntendedUse = {
    __typename?: 'IntendedUse';
    /** Primary Use cases for the model. */
    primaryUses?: Maybe<Array<Scalars['String']>>;
    /** Primary Intended Users */
    primaryUsers?: Maybe<Array<IntendedUserType>>;
    /** Out of scope uses of the MLModel */
    outOfScopeUses?: Maybe<Array<Scalars['String']>>;
};

export enum IntendedUserType {
    /** Developed for Enterprise Users */
    Enterprise = 'ENTERPRISE',
    /** Developed for Hobbyists */
    Hobby = 'HOBBY',
    /** Developed for Entertainment Purposes */
    Entertainment = 'ENTERTAINMENT',
}

export type KeyValueSchema = {
    __typename?: 'KeyValueSchema';
    /** Raw key schema */
    keySchema: Scalars['String'];
    /** Raw value schema */
    valueSchema: Scalars['String'];
};

export type MlModel = Entity & {
    __typename?: 'MLModel';
    /** The unique mlmodel URN */
    urn: Scalars['String'];
    /** GMS Entity Type */
    type: EntityType;
    /** MLModel native name */
    name: Scalars['String'];
    /** Fabric type where mlmodel belongs to or where it was generated */
    origin: FabricType;
    /** Human readable description for mlmodel */
    description?: Maybe<Scalars['String']>;
    /** Tags used for searching mlmodel */
    tags: Array<Scalars['String']>;
    /** Ownership metadata of the mlmodel */
    ownership?: Maybe<Ownership>;
    /** ModelProperties metadata of the mlmodel */
    properties?: Maybe<MlModelProperties>;
    /** Intended use of the mlmodel */
    intendedUse?: Maybe<IntendedUse>;
    /** Factors metadata of the mlmodel */
    factorPrompts?: Maybe<MlModelFactorPrompts>;
    /** Metrics metadata of the mlmodel */
    metrics?: Maybe<Metrics>;
    /** Evaluation Data of the mlmodel */
    evaluationData?: Maybe<Array<BaseData>>;
    /** Training Data of the mlmodel */
    trainingData?: Maybe<Array<BaseData>>;
    /** Quantitative Analyses of the mlmodel */
    quantitativeAnalyses?: Maybe<QuantitativeAnalyses>;
    /** Ethical Considerations of the mlmodel */
    ethicalConsiderations?: Maybe<EthicalConsiderations>;
    /** Caveats and Recommendations of the mlmodel */
    caveatsAndRecommendations?: Maybe<CaveatsAndRecommendations>;
    /** References to internal resources related to the mlmodel */
    institutionalMemory?: Maybe<InstitutionalMemory>;
    /** Source Code */
    sourceCode?: Maybe<SourceCode>;
    /** Status metadata of the mlmodel */
    status?: Maybe<Status>;
    /** Cost Aspect of the mlmodel */
    cost?: Maybe<Cost>;
    /** Deprecation */
    deprecation?: Maybe<Deprecation>;
};

export type MlModelFactorPrompts = {
    __typename?: 'MLModelFactorPrompts';
    /** What are foreseeable salient factors for which MLModel performance may vary, and how were these determined? */
    relevantFactors?: Maybe<Array<MlModelFactors>>;
    /** Which factors are being reported, and why were these chosen? */
    evaluationFactors?: Maybe<Array<MlModelFactors>>;
};

export type MlModelFactors = {
    __typename?: 'MLModelFactors';
    /** Distinct categories with similar characteristics that are present in the evaluation data instances. */
    groups?: Maybe<Array<Scalars['String']>>;
    /** Instrumentation used for MLModel */
    instrumentation?: Maybe<Array<Scalars['String']>>;
    /** Environment in which the MLModel is deployed. */
    environment?: Maybe<Array<Scalars['String']>>;
};

export type MlModelProperties = {
    __typename?: 'MLModelProperties';
    description?: Maybe<Scalars['String']>;
    date?: Maybe<Scalars['Long']>;
    version?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    hyperParameters?: Maybe<HyperParameterMap>;
    mlFeatures?: Maybe<Array<Scalars['String']>>;
    tags?: Maybe<Array<Scalars['String']>>;
};

export type MatchedField = {
    __typename?: 'MatchedField';
    /** Name of the field that matched */
    name: Scalars['String'];
    /** Value of the field that matched */
    value: Scalars['String'];
};

export type Metrics = {
    __typename?: 'Metrics';
    /** Measures of MLModel performance */
    performanceMeasures?: Maybe<Array<Scalars['String']>>;
    /** Decision Thresholds used (if any)? */
    decisionThreshold?: Maybe<Array<Scalars['String']>>;
};

export type Mutation = {
    __typename?: 'Mutation';
    updateDataset?: Maybe<Dataset>;
    updateChart?: Maybe<Chart>;
    updateDashboard?: Maybe<Dashboard>;
    updateDataFlow?: Maybe<DataFlow>;
    updateDataJob?: Maybe<DataJob>;
    updateTag?: Maybe<Tag>;
};

export type MutationUpdateDatasetArgs = {
    input: DatasetUpdateInput;
};

export type MutationUpdateChartArgs = {
    input: ChartUpdateInput;
};

export type MutationUpdateDashboardArgs = {
    input: DashboardUpdateInput;
};

export type MutationUpdateDataFlowArgs = {
    input: DataFlowUpdateInput;
};

export type MutationUpdateDataJobArgs = {
    input: DataJobUpdateInput;
};

export type MutationUpdateTagArgs = {
    input: TagUpdate;
};

export type NamedBar = {
    __typename?: 'NamedBar';
    name: Scalars['String'];
    segments: Array<BarSegment>;
};

export type NamedLine = {
    __typename?: 'NamedLine';
    name: Scalars['String'];
    data: Array<NumericDataPoint>;
};

export type NumericDataPoint = {
    __typename?: 'NumericDataPoint';
    x: Scalars['String'];
    y: Scalars['Int'];
};

export type Owner = {
    __typename?: 'Owner';
    /** Owner object */
    owner: OwnerType;
    /** The type of the ownership */
    type: OwnershipType;
    /** Source information for the ownership */
    source?: Maybe<OwnershipSource>;
};

export type OwnerType = CorpUser | CorpGroup;

export type OwnerUpdate = {
    /** The owner URN, eg urn:li:corpuser:1 */
    owner: Scalars['String'];
    /** The owner role type */
    type: OwnershipType;
};

export type Ownership = {
    __typename?: 'Ownership';
    /** List of owners of the entity */
    owners?: Maybe<Array<Owner>>;
    /** Audit stamp containing who last modified the record and when */
    lastModified: AuditStamp;
};

export type OwnershipSource = {
    __typename?: 'OwnershipSource';
    /** The type of the source */
    type: OwnershipSourceType;
    /** A reference URL for the source */
    url?: Maybe<Scalars['String']>;
};

export enum OwnershipSourceType {
    /** Auditing system or audit logs */
    Audit = 'AUDIT',
    /** Database, e.g. GRANTS table */
    Database = 'DATABASE',
    /** File system, e.g. file/directory owner */
    FileSystem = 'FILE_SYSTEM',
    /** Issue tracking system, e.g. Jira */
    IssueTrackingSystem = 'ISSUE_TRACKING_SYSTEM',
    /** Manually provided by a user */
    Manual = 'MANUAL',
    /** Other ownership-like service, e.g. Nuage, ACL service etc */
    Service = 'SERVICE',
    /** SCM system, e.g. GIT, SVN */
    SourceControl = 'SOURCE_CONTROL',
    /** Other sources */
    Other = 'OTHER',
}

export enum OwnershipType {
    /** A person or group that is in charge of developing the code */
    Developer = 'DEVELOPER',
    /** A person or group that is owning the data */
    Dataowner = 'DATAOWNER',
    /** A person or a group that overseas the operation, e.g. a DBA or SRE. */
    Delegate = 'DELEGATE',
    /** A person, group, or service that produces/generates the data */
    Producer = 'PRODUCER',
    /** A person, group, or service that consumes the data */
    Consumer = 'CONSUMER',
    /** A person or a group that has direct business interest */
    Stakeholder = 'STAKEHOLDER',
}

export type OwnershipUpdate = {
    /** The list of owners */
    owners: Array<OwnerUpdate>;
};

export enum PlatformNativeType {
    /** Table */
    Table = 'TABLE',
    /** View */
    View = 'VIEW',
    /** Directory in file system */
    Directory = 'DIRECTORY',
    /** Stream */
    Stream = 'STREAM',
    /** Bucket in key value store */
    Bucket = 'BUCKET',
}

export type PlatformSchema = TableSchema | KeyValueSchema;

export enum PlatformType {
    /** Value for a file system */
    FileSystem = 'FILE_SYSTEM',
    /** Value for a key value store */
    KeyValueStore = 'KEY_VALUE_STORE',
    /** Value for a message broker */
    MessageBroker = 'MESSAGE_BROKER',
    /** Value for an object store */
    ObjectStore = 'OBJECT_STORE',
    /** Value for an OLAP datastore */
    OlapDatastore = 'OLAP_DATASTORE',
    /** Value for a query engine */
    QueryEngine = 'QUERY_ENGINE',
    /** Value for a relational database */
    RelationalDb = 'RELATIONAL_DB',
    /** Value for a search engine */
    SearchEngine = 'SEARCH_ENGINE',
    /** Value for other platforms */
    Others = 'OTHERS',
}

export type QuantitativeAnalyses = {
    __typename?: 'QuantitativeAnalyses';
    /** Link to a dashboard with results showing how the model performed with respect to each factor */
    unitaryResults?: Maybe<ResultsType>;
    /** Link to a dashboard with results showing how the model performed with respect to the intersection of evaluated factors? */
    intersectionalResults?: Maybe<ResultsType>;
};

export type Query = {
    __typename?: 'Query';
    /** Autocomplete a search query against specific entity */
    autoComplete?: Maybe<AutoCompleteResults>;
    /** Autocomplete a search query against DataHub entities */
    autoCompleteForAll?: Maybe<AutoCompleteAllResults>;
    /** Browse DataHub entities by path */
    browse?: Maybe<BrowseResults>;
    /** Retrieve the browse path(s) corresponding to an entity */
    browsePaths?: Maybe<Array<BrowsePath>>;
    /** Fetch a Chart by primary key */
    chart?: Maybe<Chart>;
    /** Fetch a CorpGroup by primary key */
    corpGroup?: Maybe<CorpGroup>;
    /** Fetch a CorpUser by primary key */
    corpUser?: Maybe<CorpUser>;
    /** Fetch a Dashboard by primary key */
    dashboard?: Maybe<Dashboard>;
    /** Fetch a DataFlow by primary key */
    dataFlow?: Maybe<DataFlow>;
    /** Fetch a DataJob by primary key */
    dataJob?: Maybe<DataJob>;
    /** Fetch a Dataset by primary key */
    dataset?: Maybe<Dataset>;
    getAnalyticsCharts: Array<AnalyticsChartGroup>;
    getHighlights: Array<Highlight>;
    /** Fetch a GlossaryTerm by primary key */
    glossaryTerm?: Maybe<GlossaryTerm>;
    isAnalyticsEnabled: Scalars['Boolean'];
    /** Fetch a MLModel by primary key */
    mlModel?: Maybe<MlModel>;
    /** Search DataHub entities */
    search?: Maybe<SearchResults>;
    /** Fetch a Tag by primary key */
    tag?: Maybe<Tag>;
};

export type QueryAutoCompleteArgs = {
    input: AutoCompleteInput;
};

export type QueryAutoCompleteForAllArgs = {
    input: AutoCompleteInput;
};

export type QueryBrowseArgs = {
    input: BrowseInput;
};

export type QueryBrowsePathsArgs = {
    input: BrowsePathsInput;
};

export type QueryChartArgs = {
    urn: Scalars['String'];
};

export type QueryCorpGroupArgs = {
    urn: Scalars['String'];
};

export type QueryCorpUserArgs = {
    urn: Scalars['String'];
};

export type QueryDashboardArgs = {
    urn: Scalars['String'];
};

export type QueryDataFlowArgs = {
    urn: Scalars['String'];
};

export type QueryDataJobArgs = {
    urn: Scalars['String'];
};

export type QueryDatasetArgs = {
    urn: Scalars['String'];
};

export type QueryGlossaryTermArgs = {
    urn: Scalars['String'];
};

export type QueryMlModelArgs = {
    urn: Scalars['String'];
};

export type QuerySearchArgs = {
    input: SearchInput;
};

export type QueryTagArgs = {
    urn: Scalars['String'];
};

export type RelatedDataset = {
    __typename?: 'RelatedDataset';
    /** The upstream dataset the lineage points to */
    dataset: Dataset;
    /** The type of the lineage */
    type: DatasetLineageType;
    /** When the lineage was created */
    created: AuditStamp;
};

export type ResultsType = StringBox;

export type Row = {
    __typename?: 'Row';
    values: Array<Scalars['String']>;
};

export type Schema = {
    __typename?: 'Schema';
    /** Dataset this schema metadata is associated with */
    datasetUrn?: Maybe<Scalars['String']>;
    /** Schema name */
    name: Scalars['String'];
    /** Platform this schema metadata is associated with */
    platformUrn: Scalars['String'];
    /** The version of the GMS Schema metadata */
    version: Scalars['Long'];
    /** The cluster this schema metadata is derived from */
    cluster?: Maybe<Scalars['String']>;
    /** The SHA1 hash of the schema content */
    hash: Scalars['String'];
    /** The native schema in the datasets platform, schemaless if it was not provided */
    platformSchema?: Maybe<PlatformSchema>;
    /** Client provided a list of fields from value schema */
    fields: Array<SchemaField>;
    /** Client provided list of fields that define primary keys to access record */
    primaryKeys?: Maybe<Array<Scalars['String']>>;
};

export type SchemaField = {
    __typename?: 'SchemaField';
    /** Flattened name of the field computed from jsonPath field */
    fieldPath: Scalars['String'];
    /** Flattened name of a field in JSON Path notation */
    jsonPath?: Maybe<Scalars['String']>;
    /** Indicates if this field is optional or nullable */
    nullable: Scalars['Boolean'];
    /** Description of the field */
    description?: Maybe<Scalars['String']>;
    /** Platform independent field type of the field */
    type: SchemaFieldDataType;
    /** The native type of the field in the datasets platform as declared by platform schema */
    nativeDataType?: Maybe<Scalars['String']>;
    /** Whether the field references its own type recursively */
    recursive: Scalars['Boolean'];
    /** Tags associated with the field */
    globalTags?: Maybe<GlobalTags>;
    /** Glossary terms associated with the field */
    glossaryTerms?: Maybe<GlossaryTerms>;
};

export enum SchemaFieldDataType {
    Boolean = 'BOOLEAN',
    Fixed = 'FIXED',
    String = 'STRING',
    Bytes = 'BYTES',
    Number = 'NUMBER',
    Date = 'DATE',
    Time = 'TIME',
    Enum = 'ENUM',
    Null = 'NULL',
    Map = 'MAP',
    Array = 'ARRAY',
    Union = 'UNION',
    Struct = 'STRUCT',
}

export type SearchInput = {
    /** Entity type to be searched */
    type: EntityType;
    /** The query string */
    query: Scalars['String'];
    /** The starting point of paginated results */
    start?: Maybe<Scalars['Int']>;
    /** The number of elements included in the results */
    count?: Maybe<Scalars['Int']>;
    /** Faceted filters applied to search results */
    filters?: Maybe<Array<FacetFilterInput>>;
};

export type SearchResult = {
    __typename?: 'SearchResult';
    /** Searched entity */
    entity: Entity;
    /** Matched field names and their values */
    matchedFields: Array<MatchedField>;
};

export type SearchResults = {
    __typename?: 'SearchResults';
    /** The starting point of paginated results */
    start: Scalars['Int'];
    /** The number of elements included in the results */
    count: Scalars['Int'];
    /** The total number of search results matching the query and filters */
    total: Scalars['Int'];
    /** The search results */
    searchResults: Array<SearchResult>;
    /** Field aggregations used for faceted search */
    facets?: Maybe<Array<FacetMetadata>>;
};

export type SourceCode = {
    __typename?: 'SourceCode';
    /** Source Code along with types */
    sourceCode?: Maybe<Array<SourceCodeUrl>>;
};

export type SourceCodeUrl = {
    __typename?: 'SourceCodeUrl';
    /** Source Code Url Types */
    type: SourceCodeUrlType;
    /** Source Code Url */
    sourceCodeUrl: Scalars['String'];
};

export enum SourceCodeUrlType {
    /** MLModel Source Code */
    MlModelSourceCode = 'ML_MODEL_SOURCE_CODE',
    /** Training Pipeline Source Code */
    TrainingPipelineSourceCode = 'TRAINING_PIPELINE_SOURCE_CODE',
    /** Evaluation Pipeline Source Code */
    EvaluationPipelineSourceCode = 'EVALUATION_PIPELINE_SOURCE_CODE',
}

export type Status = {
    __typename?: 'Status';
    /** Whether the entity is removed or not */
    removed: Scalars['Boolean'];
};

export type StringBox = {
    __typename?: 'StringBox';
    stringValue: Scalars['String'];
};

export type StringMapEntry = {
    __typename?: 'StringMapEntry';
    key: Scalars['String'];
    value?: Maybe<Scalars['String']>;
};

export type TableChart = {
    __typename?: 'TableChart';
    title: Scalars['String'];
    columns: Array<Scalars['String']>;
    rows: Array<Row>;
};

export type TableSchema = {
    __typename?: 'TableSchema';
    /** Raw table schema */
    schema: Scalars['String'];
};

export type Tag = Entity & {
    __typename?: 'Tag';
    urn: Scalars['String'];
    /** GMS Entity Type */
    type: EntityType;
    name: Scalars['String'];
    /** Description of the tag */
    description?: Maybe<Scalars['String']>;
    /** Ownership metadata of the dataset */
    ownership?: Maybe<Ownership>;
};

export type TagAssociation = {
    __typename?: 'TagAssociation';
    tag: Tag;
};

export type TagAssociationUpdate = {
    tag: TagUpdate;
};

export type TagUpdate = {
    urn: Scalars['String'];
    name: Scalars['String'];
    /** Description of the tag */
    description?: Maybe<Scalars['String']>;
    /** Ownership metadata of the tag */
    ownership?: Maybe<OwnershipUpdate>;
};

export type TimeSeriesChart = {
    __typename?: 'TimeSeriesChart';
    title: Scalars['String'];
    lines: Array<NamedLine>;
    dateRange: DateRange;
    interval: DateInterval;
};

export type UpstreamEntityRelationships = {
    __typename?: 'UpstreamEntityRelationships';
    entities?: Maybe<Array<Maybe<EntityRelationship>>>;
};

export type UpstreamLineage = {
    __typename?: 'UpstreamLineage';
    /** List of upstream datasets */
    upstreams: Array<RelatedDataset>;
};
