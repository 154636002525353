/* eslint-disable */
import * as Types from '../types.generated';

import { GlobalTagsFieldsFragment } from './fragments.generated';
import { gql } from '@apollo/client';
import { GlobalTagsFieldsFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
export type GetUserQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetUserQuery = { __typename?: 'Query' } & {
    corpUser?: Types.Maybe<
        { __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'username'> & {
                info?: Types.Maybe<
                    { __typename?: 'CorpUserInfo' } & Pick<
                        Types.CorpUserInfo,
                        'active' | 'displayName' | 'title' | 'firstName' | 'lastName' | 'fullName' | 'email'
                    >
                >;
                editableInfo?: Types.Maybe<
                    { __typename?: 'CorpUserEditableInfo' } & Pick<
                        Types.CorpUserEditableInfo,
                        'pictureLink' | 'teams' | 'skills'
                    >
                >;
                globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
            }
    >;
};

export type GetUserGroupQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetUserGroupQuery = { __typename?: 'Query' } & {
    corpGroup?: Types.Maybe<
        { __typename?: 'CorpGroup' } & Pick<Types.CorpGroup, 'urn' | 'type' | 'name'> & {
                info?: Types.Maybe<
                    { __typename?: 'CorpGroupInfo' } & Pick<Types.CorpGroupInfo, 'email' | 'groups'> & {
                            admins?: Types.Maybe<
                                Array<
                                    { __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'username'> & {
                                            info?: Types.Maybe<
                                                { __typename?: 'CorpUserInfo' } & Pick<
                                                    Types.CorpUserInfo,
                                                    | 'active'
                                                    | 'displayName'
                                                    | 'title'
                                                    | 'email'
                                                    | 'firstName'
                                                    | 'lastName'
                                                    | 'fullName'
                                                >
                                            >;
                                            editableInfo?: Types.Maybe<
                                                { __typename?: 'CorpUserEditableInfo' } & Pick<
                                                    Types.CorpUserEditableInfo,
                                                    'pictureLink' | 'teams' | 'skills'
                                                >
                                            >;
                                        }
                                >
                            >;
                            members?: Types.Maybe<
                                Array<
                                    { __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'username'> & {
                                            info?: Types.Maybe<
                                                { __typename?: 'CorpUserInfo' } & Pick<
                                                    Types.CorpUserInfo,
                                                    | 'active'
                                                    | 'displayName'
                                                    | 'title'
                                                    | 'email'
                                                    | 'firstName'
                                                    | 'lastName'
                                                    | 'fullName'
                                                >
                                            >;
                                            editableInfo?: Types.Maybe<
                                                { __typename?: 'CorpUserEditableInfo' } & Pick<
                                                    Types.CorpUserEditableInfo,
                                                    'pictureLink' | 'teams' | 'skills'
                                                >
                                            >;
                                        }
                                >
                            >;
                        }
                >;
            }
    >;
};

export const GetUserDocument = gql`
    query getUser($urn: String!) {
        corpUser(urn: $urn) {
            urn
            username
            info {
                active
                displayName
                title
                firstName
                lastName
                fullName
                email
            }
            editableInfo {
                pictureLink
                teams
                skills
            }
            globalTags {
                ...globalTagsFields
            }
        }
    }
    ${GlobalTagsFieldsFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
    return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
}
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
    return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserGroupDocument = gql`
    query getUserGroup($urn: String!) {
        corpGroup(urn: $urn) {
            urn
            type
            name
            info {
                email
                admins {
                    urn
                    username
                    info {
                        active
                        displayName
                        title
                        email
                        firstName
                        lastName
                        fullName
                    }
                    editableInfo {
                        pictureLink
                        teams
                        skills
                    }
                }
                members {
                    urn
                    username
                    info {
                        active
                        displayName
                        title
                        email
                        firstName
                        lastName
                        fullName
                    }
                    editableInfo {
                        pictureLink
                        teams
                        skills
                    }
                }
                groups
            }
        }
    }
`;

/**
 * __useGetUserGroupQuery__
 *
 * To run a query within a React component, call `useGetUserGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserGroupQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetUserGroupQuery(
    baseOptions: Apollo.QueryHookOptions<GetUserGroupQuery, GetUserGroupQueryVariables>,
) {
    return Apollo.useQuery<GetUserGroupQuery, GetUserGroupQueryVariables>(GetUserGroupDocument, baseOptions);
}
export function useGetUserGroupLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetUserGroupQuery, GetUserGroupQueryVariables>,
) {
    return Apollo.useLazyQuery<GetUserGroupQuery, GetUserGroupQueryVariables>(GetUserGroupDocument, baseOptions);
}
export type GetUserGroupQueryHookResult = ReturnType<typeof useGetUserGroupQuery>;
export type GetUserGroupLazyQueryHookResult = ReturnType<typeof useGetUserGroupLazyQuery>;
export type GetUserGroupQueryResult = Apollo.QueryResult<GetUserGroupQuery, GetUserGroupQueryVariables>;
