/* eslint-disable */
import * as Types from '../types.generated';

import {
    OwnershipFieldsFragment,
    GlobalTagsFieldsFragment,
    DataJobFieldsFragment,
    GlossaryTermsFragment,
    NonRecursiveDataFlowFieldsFragment,
    NonRecursiveDatasetFieldsFragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    OwnershipFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    DataJobFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
    NonRecursiveDatasetFieldsFragmentDoc,
} from './fragments.generated';
import * as Apollo from '@apollo/client';
export type GetBrowsePathsQueryVariables = Types.Exact<{
    input: Types.BrowsePathsInput;
}>;

export type GetBrowsePathsQuery = { __typename?: 'Query' } & {
    browsePaths?: Types.Maybe<Array<{ __typename?: 'BrowsePath' } & Pick<Types.BrowsePath, 'path'>>>;
};

export type GetBrowseResultsQueryVariables = Types.Exact<{
    input: Types.BrowseInput;
}>;

export type GetBrowseResultsQuery = { __typename?: 'Query' } & {
    browse?: Types.Maybe<
        { __typename?: 'BrowseResults' } & Pick<Types.BrowseResults, 'start' | 'count' | 'total'> & {
                entities: Array<
                    | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type' | 'tool' | 'chartId'> & {
                              info?: Types.Maybe<
                                  { __typename?: 'ChartInfo' } & Pick<
                                      Types.ChartInfo,
                                      'name' | 'description' | 'externalUrl' | 'type' | 'access'
                                  > & { lastModified: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'> }
                              >;
                              ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                              globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                          })
                    | ({ __typename?: 'CorpGroup' } & Pick<Types.CorpGroup, 'urn' | 'type'>)
                    | ({ __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'type'>)
                    | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type' | 'tool' | 'dashboardId'> & {
                              info?: Types.Maybe<
                                  { __typename?: 'DashboardInfo' } & Pick<
                                      Types.DashboardInfo,
                                      'name' | 'description' | 'externalUrl' | 'access'
                                  > & { lastModified: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'> }
                              >;
                              ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                              globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                          })
                    | ({ __typename?: 'DataFlow' } & Pick<
                          Types.DataFlow,
                          'urn' | 'type' | 'orchestrator' | 'flowId' | 'cluster'
                      > & {
                              info?: Types.Maybe<
                                  { __typename?: 'DataFlowInfo' } & Pick<
                                      Types.DataFlowInfo,
                                      'name' | 'description' | 'project'
                                  >
                              >;
                              ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                              globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                          })
                    | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                    | ({ __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'urn' | 'type'>)
                    | ({ __typename?: 'Dataset' } & Pick<
                          Types.Dataset,
                          'name' | 'origin' | 'description' | 'tags' | 'urn' | 'type'
                      > & {
                              platform: { __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'name'> & {
                                      info?: Types.Maybe<
                                          { __typename?: 'DataPlatformInfo' } & Pick<Types.DataPlatformInfo, 'logoUrl'>
                                      >;
                                  };
                              ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                              globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                          })
                    | ({ __typename?: 'GlossaryTerm' } & Pick<Types.GlossaryTerm, 'name' | 'urn' | 'type'> & {
                              ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                              glossaryTermInfo: { __typename?: 'GlossaryTermInfo' } & Pick<
                                  Types.GlossaryTermInfo,
                                  'definition' | 'termSource' | 'sourceRef' | 'sourceUrl'
                              > & {
                                      customProperties?: Types.Maybe<
                                          Array<
                                              { __typename?: 'StringMapEntry' } & Pick<
                                                  Types.StringMapEntry,
                                                  'key' | 'value'
                                              >
                                          >
                                      >;
                                  };
                          })
                    | ({ __typename?: 'MLModel' } & Pick<Types.MlModel, 'urn' | 'type'>)
                    | ({ __typename?: 'Tag' } & Pick<Types.Tag, 'urn' | 'type'>)
                >;
                metadata: { __typename?: 'BrowseResultMetadata' } & Pick<
                    Types.BrowseResultMetadata,
                    'path' | 'totalNumEntities'
                > & {
                        groups: Array<
                            { __typename?: 'BrowseResultGroup' } & Pick<Types.BrowseResultGroup, 'name' | 'count'>
                        >;
                    };
            }
    >;
};

export const GetBrowsePathsDocument = gql`
    query getBrowsePaths($input: BrowsePathsInput!) {
        browsePaths(input: $input) {
            path
        }
    }
`;

/**
 * __useGetBrowsePathsQuery__
 *
 * To run a query within a React component, call `useGetBrowsePathsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrowsePathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrowsePathsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBrowsePathsQuery(
    baseOptions: Apollo.QueryHookOptions<GetBrowsePathsQuery, GetBrowsePathsQueryVariables>,
) {
    return Apollo.useQuery<GetBrowsePathsQuery, GetBrowsePathsQueryVariables>(GetBrowsePathsDocument, baseOptions);
}
export function useGetBrowsePathsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetBrowsePathsQuery, GetBrowsePathsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetBrowsePathsQuery, GetBrowsePathsQueryVariables>(GetBrowsePathsDocument, baseOptions);
}
export type GetBrowsePathsQueryHookResult = ReturnType<typeof useGetBrowsePathsQuery>;
export type GetBrowsePathsLazyQueryHookResult = ReturnType<typeof useGetBrowsePathsLazyQuery>;
export type GetBrowsePathsQueryResult = Apollo.QueryResult<GetBrowsePathsQuery, GetBrowsePathsQueryVariables>;
export const GetBrowseResultsDocument = gql`
    query getBrowseResults($input: BrowseInput!) {
        browse(input: $input) {
            entities {
                urn
                type
                ... on Dataset {
                    name
                    origin
                    description
                    platform {
                        name
                        info {
                            logoUrl
                        }
                    }
                    tags
                    ownership {
                        ...ownershipFields
                    }
                    globalTags {
                        ...globalTagsFields
                    }
                }
                ... on Dashboard {
                    urn
                    type
                    tool
                    dashboardId
                    info {
                        name
                        description
                        externalUrl
                        access
                        lastModified {
                            time
                        }
                    }
                    ownership {
                        ...ownershipFields
                    }
                    globalTags {
                        ...globalTagsFields
                    }
                }
                ... on GlossaryTerm {
                    name
                    ownership {
                        ...ownershipFields
                    }
                    glossaryTermInfo {
                        definition
                        termSource
                        sourceRef
                        sourceUrl
                        customProperties {
                            key
                            value
                        }
                    }
                }
                ... on Chart {
                    urn
                    type
                    tool
                    chartId
                    info {
                        name
                        description
                        externalUrl
                        type
                        access
                        lastModified {
                            time
                        }
                    }
                    ownership {
                        ...ownershipFields
                    }
                    globalTags {
                        ...globalTagsFields
                    }
                }
                ... on DataFlow {
                    urn
                    type
                    orchestrator
                    flowId
                    cluster
                    info {
                        name
                        description
                        project
                    }
                    ownership {
                        ...ownershipFields
                    }
                    globalTags {
                        ...globalTagsFields
                    }
                }
            }
            start
            count
            total
            metadata {
                path
                groups {
                    name
                    count
                }
                totalNumEntities
            }
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
`;

/**
 * __useGetBrowseResultsQuery__
 *
 * To run a query within a React component, call `useGetBrowseResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrowseResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrowseResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBrowseResultsQuery(
    baseOptions: Apollo.QueryHookOptions<GetBrowseResultsQuery, GetBrowseResultsQueryVariables>,
) {
    return Apollo.useQuery<GetBrowseResultsQuery, GetBrowseResultsQueryVariables>(
        GetBrowseResultsDocument,
        baseOptions,
    );
}
export function useGetBrowseResultsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetBrowseResultsQuery, GetBrowseResultsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetBrowseResultsQuery, GetBrowseResultsQueryVariables>(
        GetBrowseResultsDocument,
        baseOptions,
    );
}
export type GetBrowseResultsQueryHookResult = ReturnType<typeof useGetBrowseResultsQuery>;
export type GetBrowseResultsLazyQueryHookResult = ReturnType<typeof useGetBrowseResultsLazyQuery>;
export type GetBrowseResultsQueryResult = Apollo.QueryResult<GetBrowseResultsQuery, GetBrowseResultsQueryVariables>;
