/* eslint-disable */
import * as Types from '../types.generated';

import { DataJobFieldsFragment, DashboardFieldsFragment } from './fragments.generated';
import { gql } from '@apollo/client';
import { DataJobFieldsFragmentDoc, DashboardFieldsFragmentDoc } from './fragments.generated';
export type ShallowLineageFields_Chart_Fragment = { __typename?: 'Chart' } & {
    downstreamLineage?: Types.Maybe<
        { __typename?: 'DownstreamEntityRelationships' } & {
            entities?: Types.Maybe<
                Array<
                    Types.Maybe<
                        { __typename?: 'EntityRelationship' } & {
                            entity?: Types.Maybe<
                                | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                                | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                                | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                                | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                            >;
                        }
                    >
                >
            >;
        }
    >;
    upstreamLineage?: Types.Maybe<
        { __typename?: 'UpstreamEntityRelationships' } & {
            entities?: Types.Maybe<
                Array<
                    Types.Maybe<
                        { __typename?: 'EntityRelationship' } & {
                            entity?: Types.Maybe<
                                | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                                | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                                | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                                | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                            >;
                        }
                    >
                >
            >;
        }
    >;
};

export type ShallowLineageFields_Dashboard_Fragment = { __typename?: 'Dashboard' } & {
    downstreamLineage?: Types.Maybe<
        { __typename?: 'DownstreamEntityRelationships' } & {
            entities?: Types.Maybe<
                Array<
                    Types.Maybe<
                        { __typename?: 'EntityRelationship' } & {
                            entity?: Types.Maybe<
                                | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                                | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                                | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                                | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                            >;
                        }
                    >
                >
            >;
        }
    >;
    upstreamLineage?: Types.Maybe<
        { __typename?: 'UpstreamEntityRelationships' } & {
            entities?: Types.Maybe<
                Array<
                    Types.Maybe<
                        { __typename?: 'EntityRelationship' } & {
                            entity?: Types.Maybe<
                                | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                                | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                                | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                                | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                            >;
                        }
                    >
                >
            >;
        }
    >;
};

export type ShallowLineageFields_DataJob_Fragment = { __typename?: 'DataJob' } & {
    downstreamLineage?: Types.Maybe<
        { __typename?: 'DownstreamEntityRelationships' } & {
            entities?: Types.Maybe<
                Array<
                    Types.Maybe<
                        { __typename?: 'EntityRelationship' } & {
                            entity?: Types.Maybe<
                                | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                                | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                                | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                                | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                            >;
                        }
                    >
                >
            >;
        }
    >;
    upstreamLineage?: Types.Maybe<
        { __typename?: 'UpstreamEntityRelationships' } & {
            entities?: Types.Maybe<
                Array<
                    Types.Maybe<
                        { __typename?: 'EntityRelationship' } & {
                            entity?: Types.Maybe<
                                | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                                | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                                | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                                | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                            >;
                        }
                    >
                >
            >;
        }
    >;
};

export type ShallowLineageFields_Dataset_Fragment = { __typename?: 'Dataset' } & {
    downstreamLineage?: Types.Maybe<
        { __typename?: 'DownstreamEntityRelationships' } & {
            entities?: Types.Maybe<
                Array<
                    Types.Maybe<
                        { __typename?: 'EntityRelationship' } & {
                            entity?: Types.Maybe<
                                | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                                | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                                | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                                | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                            >;
                        }
                    >
                >
            >;
        }
    >;
    upstreamLineage?: Types.Maybe<
        { __typename?: 'UpstreamEntityRelationships' } & {
            entities?: Types.Maybe<
                Array<
                    Types.Maybe<
                        { __typename?: 'EntityRelationship' } & {
                            entity?: Types.Maybe<
                                | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                                | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                                | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                                | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                            >;
                        }
                    >
                >
            >;
        }
    >;
};

export type ShallowLineageFieldsFragment =
    | ShallowLineageFields_Chart_Fragment
    | ShallowLineageFields_Dashboard_Fragment
    | ShallowLineageFields_DataJob_Fragment
    | ShallowLineageFields_Dataset_Fragment;

export type UpstreamRelationshipFieldsFragment = { __typename?: 'UpstreamEntityRelationships' } & {
    entities?: Types.Maybe<
        Array<
            Types.Maybe<
                { __typename?: 'EntityRelationship' } & {
                    entity?: Types.Maybe<
                        | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'tool' | 'chartId' | 'urn' | 'type'> & {
                                  info?: Types.Maybe<
                                      { __typename?: 'ChartInfo' } & Pick<Types.ChartInfo, 'name' | 'description'> & {
                                              inputs?: Types.Maybe<
                                                  Array<{ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn'>>
                                              >;
                                          }
                                  >;
                                  downstreamLineage?: Types.Maybe<
                                      { __typename?: 'DownstreamEntityRelationships' } & {
                                          entities?: Types.Maybe<
                                              Array<
                                                  Types.Maybe<
                                                      { __typename?: 'EntityRelationship' } & {
                                                          entity?: Types.Maybe<
                                                              | ({ __typename?: 'Chart' } & Pick<
                                                                    Types.Chart,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dashboard' } & Pick<
                                                                    Types.Dashboard,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'DataJob' } & Pick<
                                                                    Types.DataJob,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dataset' } & Pick<
                                                                    Types.Dataset,
                                                                    'urn' | 'type'
                                                                >)
                                                          >;
                                                      }
                                                  >
                                              >
                                          >;
                                      }
                                  >;
                                  upstreamLineage?: Types.Maybe<
                                      { __typename?: 'UpstreamEntityRelationships' } & {
                                          entities?: Types.Maybe<
                                              Array<
                                                  Types.Maybe<
                                                      { __typename?: 'EntityRelationship' } & {
                                                          entity?: Types.Maybe<
                                                              | ({ __typename?: 'Chart' } & Pick<
                                                                    Types.Chart,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dashboard' } & Pick<
                                                                    Types.Dashboard,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'DataJob' } & Pick<
                                                                    Types.DataJob,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dataset' } & Pick<
                                                                    Types.Dataset,
                                                                    'urn' | 'type'
                                                                >)
                                                          >;
                                                      }
                                                  >
                                              >
                                          >;
                                      }
                                  >;
                              })
                        | ({ __typename?: 'Dashboard' } & Pick<
                              Types.Dashboard,
                              'urn' | 'type' | 'tool' | 'dashboardId'
                          > & {
                                  info?: Types.Maybe<
                                      { __typename?: 'DashboardInfo' } & Pick<
                                          Types.DashboardInfo,
                                          'externalUrl' | 'name' | 'description'
                                      >
                                  >;
                                  downstreamLineage?: Types.Maybe<
                                      { __typename?: 'DownstreamEntityRelationships' } & {
                                          entities?: Types.Maybe<
                                              Array<
                                                  Types.Maybe<
                                                      { __typename?: 'EntityRelationship' } & {
                                                          entity?: Types.Maybe<
                                                              | ({ __typename?: 'Chart' } & Pick<
                                                                    Types.Chart,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dashboard' } & Pick<
                                                                    Types.Dashboard,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'DataJob' } & Pick<
                                                                    Types.DataJob,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dataset' } & Pick<
                                                                    Types.Dataset,
                                                                    'urn' | 'type'
                                                                >)
                                                          >;
                                                      }
                                                  >
                                              >
                                          >;
                                      }
                                  >;
                                  upstreamLineage?: Types.Maybe<
                                      { __typename?: 'UpstreamEntityRelationships' } & {
                                          entities?: Types.Maybe<
                                              Array<
                                                  Types.Maybe<
                                                      { __typename?: 'EntityRelationship' } & {
                                                          entity?: Types.Maybe<
                                                              | ({ __typename?: 'Chart' } & Pick<
                                                                    Types.Chart,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dashboard' } & Pick<
                                                                    Types.Dashboard,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'DataJob' } & Pick<
                                                                    Types.DataJob,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dataset' } & Pick<
                                                                    Types.Dataset,
                                                                    'urn' | 'type'
                                                                >)
                                                          >;
                                                      }
                                                  >
                                              >
                                          >;
                                      }
                                  >;
                              })
                        | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type' | 'jobId'> & {
                                  dataFlow?: Types.Maybe<
                                      { __typename?: 'DataFlow' } & Pick<
                                          Types.DataFlow,
                                          'urn' | 'type' | 'orchestrator' | 'flowId' | 'cluster'
                                      >
                                  >;
                                  info?: Types.Maybe<
                                      { __typename?: 'DataJobInfo' } & Pick<
                                          Types.DataJobInfo,
                                          'name' | 'description' | 'externalUrl'
                                      >
                                  >;
                                  downstreamLineage?: Types.Maybe<
                                      { __typename?: 'DownstreamEntityRelationships' } & {
                                          entities?: Types.Maybe<
                                              Array<
                                                  Types.Maybe<
                                                      { __typename?: 'EntityRelationship' } & {
                                                          entity?: Types.Maybe<
                                                              | ({ __typename?: 'Chart' } & Pick<
                                                                    Types.Chart,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dashboard' } & Pick<
                                                                    Types.Dashboard,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'DataJob' } & Pick<
                                                                    Types.DataJob,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dataset' } & Pick<
                                                                    Types.Dataset,
                                                                    'urn' | 'type'
                                                                >)
                                                          >;
                                                      }
                                                  >
                                              >
                                          >;
                                      }
                                  >;
                                  upstreamLineage?: Types.Maybe<
                                      { __typename?: 'UpstreamEntityRelationships' } & {
                                          entities?: Types.Maybe<
                                              Array<
                                                  Types.Maybe<
                                                      { __typename?: 'EntityRelationship' } & {
                                                          entity?: Types.Maybe<
                                                              | ({ __typename?: 'Chart' } & Pick<
                                                                    Types.Chart,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dashboard' } & Pick<
                                                                    Types.Dashboard,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'DataJob' } & Pick<
                                                                    Types.DataJob,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dataset' } & Pick<
                                                                    Types.Dataset,
                                                                    'urn' | 'type'
                                                                >)
                                                          >;
                                                      }
                                                  >
                                              >
                                          >;
                                      }
                                  >;
                              })
                        | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'name' | 'description' | 'type'> & {
                                  platform: { __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'name'> & {
                                          info?: Types.Maybe<
                                              { __typename?: 'DataPlatformInfo' } & Pick<
                                                  Types.DataPlatformInfo,
                                                  'logoUrl'
                                              >
                                          >;
                                      };
                                  downstreamLineage?: Types.Maybe<
                                      { __typename?: 'DownstreamEntityRelationships' } & {
                                          entities?: Types.Maybe<
                                              Array<
                                                  Types.Maybe<
                                                      { __typename?: 'EntityRelationship' } & {
                                                          entity?: Types.Maybe<
                                                              | ({ __typename?: 'Chart' } & Pick<
                                                                    Types.Chart,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dashboard' } & Pick<
                                                                    Types.Dashboard,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'DataJob' } & Pick<
                                                                    Types.DataJob,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dataset' } & Pick<
                                                                    Types.Dataset,
                                                                    'urn' | 'type'
                                                                >)
                                                          >;
                                                      }
                                                  >
                                              >
                                          >;
                                      }
                                  >;
                                  upstreamLineage?: Types.Maybe<
                                      { __typename?: 'UpstreamEntityRelationships' } & {
                                          entities?: Types.Maybe<
                                              Array<
                                                  Types.Maybe<
                                                      { __typename?: 'EntityRelationship' } & {
                                                          entity?: Types.Maybe<
                                                              | ({ __typename?: 'Chart' } & Pick<
                                                                    Types.Chart,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dashboard' } & Pick<
                                                                    Types.Dashboard,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'DataJob' } & Pick<
                                                                    Types.DataJob,
                                                                    'urn' | 'type'
                                                                >)
                                                              | ({ __typename?: 'Dataset' } & Pick<
                                                                    Types.Dataset,
                                                                    'urn' | 'type'
                                                                >)
                                                          >;
                                                      }
                                                  >
                                              >
                                          >;
                                      }
                                  >;
                              })
                    >;
                }
            >
        >
    >;
};

export type DownstreamRelationshipFieldsFragment = { __typename?: 'DownstreamEntityRelationships' } & {
    entities?: Types.Maybe<
        Array<
            Types.Maybe<
                { __typename?: 'EntityRelationship' } & {
                    entity?: Types.Maybe<
                        | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'tool' | 'chartId' | 'urn' | 'type'> & {
                                  info?: Types.Maybe<
                                      { __typename?: 'ChartInfo' } & Pick<Types.ChartInfo, 'name' | 'description'> & {
                                              inputs?: Types.Maybe<
                                                  Array<{ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn'>>
                                              >;
                                          }
                                  >;
                              } & ShallowLineageFields_Chart_Fragment)
                        | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'> &
                              DashboardFieldsFragment &
                              ShallowLineageFields_Dashboard_Fragment)
                        | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'> &
                              DataJobFieldsFragment &
                              ShallowLineageFields_DataJob_Fragment)
                        | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'name' | 'description' | 'type'> & {
                                  platform: { __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'name'> & {
                                          info?: Types.Maybe<
                                              { __typename?: 'DataPlatformInfo' } & Pick<
                                                  Types.DataPlatformInfo,
                                                  'logoUrl'
                                              >
                                          >;
                                      };
                              } & ShallowLineageFields_Dataset_Fragment)
                    >;
                }
            >
        >
    >;
};

export const UpstreamRelationshipFieldsFragmentDoc = gql`
    fragment upstreamRelationshipFields on UpstreamEntityRelationships {
        entities {
            entity {
                urn
                type
                downstreamLineage {
                    entities {
                        entity {
                            urn
                            type
                        }
                    }
                }
                upstreamLineage {
                    entities {
                        entity {
                            urn
                            type
                        }
                    }
                }
                ... on DataJob {
                    urn
                    type
                    dataFlow {
                        urn
                        type
                        orchestrator
                        flowId
                        cluster
                    }
                    jobId
                    info {
                        name
                        description
                        externalUrl
                    }
                }
                ... on Dashboard {
                    urn
                    type
                    tool
                    dashboardId
                    info {
                        externalUrl
                        name
                        description
                    }
                }
                ... on Chart {
                    tool
                    chartId
                    info {
                        name
                        description
                        inputs {
                            urn
                        }
                    }
                }
                ... on Dataset {
                    urn
                    name
                    description
                    platform {
                        name
                        info {
                            logoUrl
                        }
                    }
                }
            }
        }
    }
`;
export const ShallowLineageFieldsFragmentDoc = gql`
    fragment shallowLineageFields on EntityWithRelationships {
        downstreamLineage {
            entities {
                entity {
                    urn
                    type
                }
            }
        }
        upstreamLineage {
            entities {
                entity {
                    urn
                    type
                }
            }
        }
    }
`;
export const DownstreamRelationshipFieldsFragmentDoc = gql`
    fragment downstreamRelationshipFields on DownstreamEntityRelationships {
        entities {
            entity {
                urn
                type
                ... on DataJob {
                    ...dataJobFields
                    ...shallowLineageFields
                }
                ... on Dashboard {
                    ...dashboardFields
                    ...shallowLineageFields
                }
                ... on Chart {
                    tool
                    chartId
                    info {
                        name
                        description
                        inputs {
                            urn
                        }
                    }
                    ...shallowLineageFields
                }
                ... on Dataset {
                    urn
                    name
                    description
                    platform {
                        name
                        info {
                            logoUrl
                        }
                    }
                    ...shallowLineageFields
                }
            }
        }
    }
    ${DataJobFieldsFragmentDoc}
    ${ShallowLineageFieldsFragmentDoc}
    ${DashboardFieldsFragmentDoc}
`;
