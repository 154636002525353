/* eslint-disable */
import * as Types from '../types.generated';

import {
    OwnershipFieldsFragment,
    GlobalTagsFieldsFragment,
    DataJobFieldsFragment,
    GlossaryTermsFragment,
    NonRecursiveDataFlowFieldsFragment,
    NonRecursiveDatasetFieldsFragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    OwnershipFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    DataJobFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
    NonRecursiveDatasetFieldsFragmentDoc,
} from './fragments.generated';
import * as Apollo from '@apollo/client';
export type GetAutoCompleteResultsQueryVariables = Types.Exact<{
    input: Types.AutoCompleteInput;
}>;

export type GetAutoCompleteResultsQuery = { __typename?: 'Query' } & {
    autoComplete?: Types.Maybe<
        { __typename?: 'AutoCompleteResults' } & Pick<Types.AutoCompleteResults, 'query' | 'suggestions'>
    >;
};

export type GetAutoCompleteAllResultsQueryVariables = Types.Exact<{
    input: Types.AutoCompleteInput;
}>;

export type GetAutoCompleteAllResultsQuery = { __typename?: 'Query' } & {
    autoCompleteForAll?: Types.Maybe<
        { __typename?: 'AutoCompleteAllResults' } & Pick<Types.AutoCompleteAllResults, 'query'> & {
                suggestions: Array<
                    { __typename?: 'AutoCompleteResultForEntity' } & Pick<
                        Types.AutoCompleteResultForEntity,
                        'type' | 'suggestions'
                    >
                >;
            }
    >;
};

export type GetSearchResultsQueryVariables = Types.Exact<{
    input: Types.SearchInput;
}>;

export type GetSearchResultsQuery = { __typename?: 'Query' } & {
    search?: Types.Maybe<
        { __typename?: 'SearchResults' } & Pick<Types.SearchResults, 'start' | 'count' | 'total'> & {
                searchResults: Array<
                    { __typename?: 'SearchResult' } & {
                        entity:
                            | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type' | 'tool' | 'chartId'> & {
                                      info?: Types.Maybe<
                                          { __typename?: 'ChartInfo' } & Pick<
                                              Types.ChartInfo,
                                              'name' | 'description' | 'externalUrl' | 'type' | 'access'
                                          > & {
                                                  lastModified: { __typename?: 'AuditStamp' } & Pick<
                                                      Types.AuditStamp,
                                                      'time'
                                                  >;
                                              }
                                      >;
                                      ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                                      globalTags?: Types.Maybe<
                                          { __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment
                                      >;
                                  })
                            | ({ __typename?: 'CorpGroup' } & Pick<Types.CorpGroup, 'urn' | 'type'>)
                            | ({ __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'username' | 'urn' | 'type'> & {
                                      info?: Types.Maybe<
                                          { __typename?: 'CorpUserInfo' } & Pick<
                                              Types.CorpUserInfo,
                                              'active' | 'displayName' | 'title' | 'firstName' | 'lastName' | 'fullName'
                                          >
                                      >;
                                      editableInfo?: Types.Maybe<
                                          { __typename?: 'CorpUserEditableInfo' } & Pick<
                                              Types.CorpUserEditableInfo,
                                              'pictureLink'
                                          >
                                      >;
                                  })
                            | ({ __typename?: 'Dashboard' } & Pick<
                                  Types.Dashboard,
                                  'urn' | 'type' | 'tool' | 'dashboardId'
                              > & {
                                      info?: Types.Maybe<
                                          { __typename?: 'DashboardInfo' } & Pick<
                                              Types.DashboardInfo,
                                              'name' | 'description' | 'externalUrl' | 'access'
                                          > & {
                                                  lastModified: { __typename?: 'AuditStamp' } & Pick<
                                                      Types.AuditStamp,
                                                      'time'
                                                  >;
                                              }
                                      >;
                                      ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                                      globalTags?: Types.Maybe<
                                          { __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment
                                      >;
                                  })
                            | ({ __typename?: 'DataFlow' } & Pick<
                                  Types.DataFlow,
                                  'urn' | 'type' | 'orchestrator' | 'flowId' | 'cluster'
                              > & {
                                      info?: Types.Maybe<
                                          { __typename?: 'DataFlowInfo' } & Pick<
                                              Types.DataFlowInfo,
                                              'name' | 'description' | 'project'
                                          >
                                      >;
                                      ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                                      globalTags?: Types.Maybe<
                                          { __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment
                                      >;
                                  })
                            | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type' | 'jobId'> & {
                                      dataFlow?: Types.Maybe<
                                          { __typename?: 'DataFlow' } & NonRecursiveDataFlowFieldsFragment
                                      >;
                                      ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                                      inputOutput?: Types.Maybe<
                                          { __typename?: 'DataJobInputOutput' } & {
                                              inputDatasets?: Types.Maybe<
                                                  Array<{ __typename?: 'Dataset' } & NonRecursiveDatasetFieldsFragment>
                                              >;
                                              outputDatasets?: Types.Maybe<
                                                  Array<{ __typename?: 'Dataset' } & NonRecursiveDatasetFieldsFragment>
                                              >;
                                          }
                                      >;
                                      info?: Types.Maybe<
                                          { __typename?: 'DataJobInfo' } & Pick<
                                              Types.DataJobInfo,
                                              'name' | 'description'
                                          >
                                      >;
                                      globalTags?: Types.Maybe<
                                          { __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment
                                      >;
                                  })
                            | ({ __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'urn' | 'type'>)
                            | ({ __typename?: 'Dataset' } & Pick<
                                  Types.Dataset,
                                  | 'name'
                                  | 'origin'
                                  | 'description'
                                  | 'uri'
                                  | 'platformNativeType'
                                  | 'tags'
                                  | 'urn'
                                  | 'type'
                              > & {
                                      platform: { __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'name'> & {
                                              info?: Types.Maybe<
                                                  { __typename?: 'DataPlatformInfo' } & Pick<
                                                      Types.DataPlatformInfo,
                                                      'logoUrl'
                                                  >
                                              >;
                                          };
                                      properties?: Types.Maybe<
                                          Array<
                                              { __typename?: 'StringMapEntry' } & Pick<
                                                  Types.StringMapEntry,
                                                  'key' | 'value'
                                              >
                                          >
                                      >;
                                      ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                                      globalTags?: Types.Maybe<
                                          { __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment
                                      >;
                                      glossaryTerms?: Types.Maybe<
                                          { __typename?: 'GlossaryTerms' } & GlossaryTermsFragment
                                      >;
                                  })
                            | ({ __typename?: 'GlossaryTerm' } & Pick<Types.GlossaryTerm, 'name' | 'urn' | 'type'> & {
                                      glossaryTermInfo: { __typename?: 'GlossaryTermInfo' } & Pick<
                                          Types.GlossaryTermInfo,
                                          'definition' | 'termSource' | 'sourceRef' | 'sourceUrl'
                                      > & {
                                              customProperties?: Types.Maybe<
                                                  Array<
                                                      { __typename?: 'StringMapEntry' } & Pick<
                                                          Types.StringMapEntry,
                                                          'key' | 'value'
                                                      >
                                                  >
                                              >;
                                          };
                                  })
                            | ({ __typename?: 'MLModel' } & Pick<Types.MlModel, 'urn' | 'type'>)
                            | ({ __typename?: 'Tag' } & Pick<Types.Tag, 'urn' | 'type'>);
                        matchedFields: Array<
                            { __typename?: 'MatchedField' } & Pick<Types.MatchedField, 'name' | 'value'>
                        >;
                    }
                >;
                facets?: Types.Maybe<
                    Array<
                        { __typename?: 'FacetMetadata' } & Pick<Types.FacetMetadata, 'field'> & {
                                aggregations: Array<
                                    { __typename?: 'AggregationMetadata' } & Pick<
                                        Types.AggregationMetadata,
                                        'value' | 'count'
                                    >
                                >;
                            }
                    >
                >;
            }
    >;
};

export const GetAutoCompleteResultsDocument = gql`
    query getAutoCompleteResults($input: AutoCompleteInput!) {
        autoComplete(input: $input) {
            query
            suggestions
        }
    }
`;

/**
 * __useGetAutoCompleteResultsQuery__
 *
 * To run a query within a React component, call `useGetAutoCompleteResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoCompleteResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoCompleteResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAutoCompleteResultsQuery(
    baseOptions: Apollo.QueryHookOptions<GetAutoCompleteResultsQuery, GetAutoCompleteResultsQueryVariables>,
) {
    return Apollo.useQuery<GetAutoCompleteResultsQuery, GetAutoCompleteResultsQueryVariables>(
        GetAutoCompleteResultsDocument,
        baseOptions,
    );
}
export function useGetAutoCompleteResultsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAutoCompleteResultsQuery, GetAutoCompleteResultsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetAutoCompleteResultsQuery, GetAutoCompleteResultsQueryVariables>(
        GetAutoCompleteResultsDocument,
        baseOptions,
    );
}
export type GetAutoCompleteResultsQueryHookResult = ReturnType<typeof useGetAutoCompleteResultsQuery>;
export type GetAutoCompleteResultsLazyQueryHookResult = ReturnType<typeof useGetAutoCompleteResultsLazyQuery>;
export type GetAutoCompleteResultsQueryResult = Apollo.QueryResult<
    GetAutoCompleteResultsQuery,
    GetAutoCompleteResultsQueryVariables
>;
export const GetAutoCompleteAllResultsDocument = gql`
    query getAutoCompleteAllResults($input: AutoCompleteInput!) {
        autoCompleteForAll(input: $input) {
            query
            suggestions {
                type
                suggestions
            }
        }
    }
`;

/**
 * __useGetAutoCompleteAllResultsQuery__
 *
 * To run a query within a React component, call `useGetAutoCompleteAllResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoCompleteAllResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoCompleteAllResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAutoCompleteAllResultsQuery(
    baseOptions: Apollo.QueryHookOptions<GetAutoCompleteAllResultsQuery, GetAutoCompleteAllResultsQueryVariables>,
) {
    return Apollo.useQuery<GetAutoCompleteAllResultsQuery, GetAutoCompleteAllResultsQueryVariables>(
        GetAutoCompleteAllResultsDocument,
        baseOptions,
    );
}
export function useGetAutoCompleteAllResultsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAutoCompleteAllResultsQuery, GetAutoCompleteAllResultsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetAutoCompleteAllResultsQuery, GetAutoCompleteAllResultsQueryVariables>(
        GetAutoCompleteAllResultsDocument,
        baseOptions,
    );
}
export type GetAutoCompleteAllResultsQueryHookResult = ReturnType<typeof useGetAutoCompleteAllResultsQuery>;
export type GetAutoCompleteAllResultsLazyQueryHookResult = ReturnType<typeof useGetAutoCompleteAllResultsLazyQuery>;
export type GetAutoCompleteAllResultsQueryResult = Apollo.QueryResult<
    GetAutoCompleteAllResultsQuery,
    GetAutoCompleteAllResultsQueryVariables
>;
export const GetSearchResultsDocument = gql`
    query getSearchResults($input: SearchInput!) {
        search(input: $input) {
            start
            count
            total
            searchResults {
                entity {
                    urn
                    type
                    ... on Dataset {
                        name
                        origin
                        description
                        uri
                        platform {
                            name
                            info {
                                logoUrl
                            }
                        }
                        platformNativeType
                        tags
                        properties {
                            key
                            value
                        }
                        ownership {
                            ...ownershipFields
                        }
                        globalTags {
                            ...globalTagsFields
                        }
                        glossaryTerms {
                            ...glossaryTerms
                        }
                    }
                    ... on CorpUser {
                        username
                        info {
                            active
                            displayName
                            title
                            firstName
                            lastName
                            fullName
                        }
                        editableInfo {
                            pictureLink
                        }
                    }
                    ... on Dashboard {
                        urn
                        type
                        tool
                        dashboardId
                        info {
                            name
                            description
                            externalUrl
                            access
                            lastModified {
                                time
                            }
                        }
                        ownership {
                            ...ownershipFields
                        }
                        globalTags {
                            ...globalTagsFields
                        }
                    }
                    ... on Chart {
                        urn
                        type
                        tool
                        chartId
                        info {
                            name
                            description
                            externalUrl
                            type
                            access
                            lastModified {
                                time
                            }
                        }
                        ownership {
                            ...ownershipFields
                        }
                        globalTags {
                            ...globalTagsFields
                        }
                    }
                    ... on DataFlow {
                        urn
                        type
                        orchestrator
                        flowId
                        cluster
                        info {
                            name
                            description
                            project
                        }
                        ownership {
                            ...ownershipFields
                        }
                        globalTags {
                            ...globalTagsFields
                        }
                    }
                    ... on DataJob {
                        urn
                        type
                        dataFlow {
                            ...nonRecursiveDataFlowFields
                        }
                        jobId
                        ownership {
                            ...ownershipFields
                        }
                        inputOutput {
                            inputDatasets {
                                ...nonRecursiveDatasetFields
                            }
                            outputDatasets {
                                ...nonRecursiveDatasetFields
                            }
                        }
                        info {
                            name
                            description
                        }
                        globalTags {
                            ...globalTagsFields
                        }
                    }
                    ... on GlossaryTerm {
                        name
                        glossaryTermInfo {
                            definition
                            termSource
                            sourceRef
                            sourceUrl
                            customProperties {
                                key
                                value
                            }
                        }
                    }
                }
                matchedFields {
                    name
                    value
                }
            }
            facets {
                field
                aggregations {
                    value
                    count
                }
            }
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${NonRecursiveDataFlowFieldsFragmentDoc}
    ${NonRecursiveDatasetFieldsFragmentDoc}
`;

/**
 * __useGetSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSearchResultsQuery(
    baseOptions: Apollo.QueryHookOptions<GetSearchResultsQuery, GetSearchResultsQueryVariables>,
) {
    return Apollo.useQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(
        GetSearchResultsDocument,
        baseOptions,
    );
}
export function useGetSearchResultsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetSearchResultsQuery, GetSearchResultsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(
        GetSearchResultsDocument,
        baseOptions,
    );
}
export type GetSearchResultsQueryHookResult = ReturnType<typeof useGetSearchResultsQuery>;
export type GetSearchResultsLazyQueryHookResult = ReturnType<typeof useGetSearchResultsLazyQuery>;
export type GetSearchResultsQueryResult = Apollo.QueryResult<GetSearchResultsQuery, GetSearchResultsQueryVariables>;
